import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/payroll.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { SpaRounded } from "@mui/icons-material";
const URL = "https://api.aspolite.com/api/payroll/GetPayrollById";
const URL1 = "https://api.aspolite.com/api/master/location";

function ViewPayroll() {
  const [options, setOptions] = useState([]);
  const [employee, SetEmployee] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [days, setDays] = useState("");
  const [exceldata, setExceldata] = useState("");
  const [locationId, setLocationId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);
  const [rowStates, setRowStates] = useState([]);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateRowState = (index, fieldName, value) => {
    setRowStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = { ...newStates[index], [fieldName]: value };
      return newStates;
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const token = localStorage.getItem("token");
  const handleChange = (e) => {
    setLocationId(e.target.value);
  };

  const handleClickOpen = () => () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const idnum = parseInt(id);
  async function handleSave(e) {
    e.preventDefault();
    if (selectedRows.length === 0 || selectedRows.length !== rows.length) {
    }

    try {
      const res = await axios.post(
        "https://api.aspolite.com/api/payroll/SubmitPayroll",
        selectedRows.map((row, index) => ({
          userId: row.userId,
          attendanceId: idnum,
          empName: row.empName,
          empCode: row.empCode,
          BasicPerMonth:
            rowStates[index]?.basicPerMonth ??
            calculateCEILING(
              row.basicPerMonth,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          BasicPerDay:
            rowStates[index]?.basicPerDay ??
            calculateCEILING(
              row.basicPerDay,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          OtRatePerHour:
            rowStates[index]?.otRate ??
            calculateCEILING(
              row.otRatePerHour,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          AllowancePerMonth:
            rowStates[index]?.allowancePM ??
            calculateCEILING(
              row.allowancePerMonth,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          Hra:
            rowStates[index]?.hra ??
            calculateCEILING(
              row.hra,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          Vda:
            rowStates[index]?.vda ??
            calculateCEILING(
              row.vda,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          pf:
            rowStates[index]?.pf ??
            calculateCEILING(
              row.pf,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          Esic:
            rowStates[index]?.esic ??
            calculateCEILING(
              row.esic,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          Red:
            rowStates[index]?.red ??
            calculateCEILING(
              row.red,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          Rot:
            rowStates[index]?.rot ??
            calculateCEILING(
              row.rot,
              row.days,
              row.holiday,
              row.present,
              index,
              row.earnedLeaves
            ),
          Issued: true,
        })),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("/payroll");
    } catch (error) {
      console.error(error);
      alert("Please select employee");
    }
  }

  const fetchHandler = async () => {
    // const id = getParameterByName("id")
    try {
      const res = await axios.get(`${URL}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRows(res.data);
      setStartingDate(dayjs(res.data.fromDate).format("MM-DD-YYYY") || "");
      setEndingDate(dayjs(res.data.toDate).format("MM-DD-YYYY") || "");
      setLocationId(res.data.locationId || "");
      setRowsPerPage(5);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchHandler1 = async () => {
    try {
      const res = await axios.get(URL1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(res.data);
      //   setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
    fetchHandler1();
  }, []);

  const handleBack = () => {
    navigate("/payroll");
  };
  const handleCheckboxChange = (event, row) => {
    const selectedRowIds = selectedRows.map((selectedRow) => selectedRow.id);
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter(
        (selectedRow) => selectedRow.id !== row.id
      );
      setSelectedRows(updatedSelectedRows);
    }
  };

  const columns = [
    { id: "checkbox", label: "", align: "center" },
    { id: "srno", label: "Sr no", align: "center" },
    { id: "code", label: "Employee Code", align: "center" },
    { id: "Name", label: "Employee Name", align: "center" },
    { id: "status", label: "Status", align: "center" },

    {
      id: "days",
      label: "Days",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "present",
      label: "Present",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "holiday",
      label: "Holiday",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "el",
      label: "EL",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "othours",
      label: "OT Hours",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },

    {
      id: "basicpermonth",
      label: "BasicPerMonth",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "basicPerday",
      label: "BasicPerDay",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "otrateperhour",
      label: "OT Rate PerHour",
      align: "center",
      format: (value) => value.toFixed(2),
      style: { minWidth: 120, width: 120 },
    },

    {
      id: "allowance per Month",
      label: "Allowance ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "hra",
      label: "hra ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "vda",
      label: "VDA ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "gross",
      label: "Gross Total",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "pf",
      label: "PF ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "esic",
      label: "esic ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "retention",
      label: "retention ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "retention",
      label: "retention OT ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "retention",
      label: "Deduction Total ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "netSalary",
      label: "net Salary ",
      style: { minWidth: 120, width: 120 },
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "center",
      format: (value) => value.toFixed(2),
    },
  ];

  const locaionName = new URLSearchParams(location.search).get("location");
  const fromDate = new URLSearchParams(location.search).get("fromDate");
  const toDate = new URLSearchParams(location.search).get("toDate");

  const handleView = async (userId) => {
    try {
      const res = await axios.get(`${URL}?Id=${id}&UserId=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // SetEmployee(res.data);
      const selectedEmployeeData = res.data.find(
        (row) => row.userId === userId
      );
      SetEmployee(selectedEmployeeData);
      setOpen(true);
      setScroll(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const calculateCEILING = (
    basicPerMonth,
    days,
    present,
    absent,
    el,
    index
  ) => {
    const result = (basicPerMonth / days) * (present + absent + (el ? el : 0));
    return Math.ceil(result);
  };

  const calculateEsicCEILING = (
    basicPerMonth,
    days,
    present,
    absent,
    othours,
    el,
    index
  ) => {
    const result =
      (basicPerMonth + othours / days) * (present + absent + (el ? el : 0));
    return Math.ceil(result);
  };
  const handleExportData = () => {
    const headers = [
      [
        "Employee Details",
        "",
        "",
        "Attendance",
        "",
        "",
        "",
        "",
        "Additions",
        "",
        "",
        "",
        "",
        "",
        "",
        "Deductions",
        "",
        "",
        "",
        "",
        "Net Salary",
        "Net Salary",
      ],
      [
        "Sr No",
        "Employee Code",
        "Employee Name",
        "Days",
        "Present",
        "Holiday",
        "Earned Leaves",
        "OT Hours",
        "Basic Per Month",
        "Basic Per Day",
        "OT Rate Per Hour",
        "Allowance Per Month",
        "HRA",
        "VDA",
        "Gross Total",
        "PF",
        "ESIC",
        "RED",
        "ROT",
        "Deductions",
        "Net Pay",
      ],
    ];

    const data = rows.map((row, index) => [
      index + 1,
      row.empCode,
      row.empName,
      row.days,
      row.present,
      row.holiday,
      row.earnedLeaves,
      row.othours,
      rowStates[index]?.basicPM ??
        calculateCEILING(
          row.basicPerMonth,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.basicPD ??
        calculateCEILING(
          row.basicPerDay,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.otRate ??
        calculateOtrate(row.otRatePerHour, row.othours, index),
      rowStates[index]?.allowancePM ??
        calculateCEILING(
          row.allowancePerMonth,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.hra ??
        calculateCEILING(
          row.hra,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.vda ??
        calculateCEILING(
          row.vda,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      (rowStates[index]?.vda ??
        calculateCEILING(
          row.vda,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        )) +
        (rowStates[index]?.basicPM ??
          calculateCEILING(
            row.basicPerMonth,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.basicPD ??
          calculateCEILING(
            row.basicPerDay,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.allowancePM ??
          calculateCEILING(
            row.allowancePerMonth,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.hra ??
          calculateCEILING(
            row.hra,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.otRate ??
          calculateOtrate(row.otRatePerHour, row.othours, index)),
      rowStates[index]?.pf ??
        calculateCEILING(
          row.pf,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.esic ??
        calculateCEILING(
          row.esic,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.red ??
        calculateCEILING(
          row.red,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),
      rowStates[index]?.rot ??
        calculateCEILING(
          row.rot,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        ),

      (rowStates[index]?.pf ??
        calculateCEILING(
          row.pf,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        )) +
        (rowStates[index]?.esic ??
          calculateCEILING(
            row.esic,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.red ??
          calculateCEILING(
            row.red,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.rot ??
          calculateCEILING(
            row.rot,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )),
      (rowStates[index]?.vda ??
        calculateCEILING(
          row.vda,
          row.days,
          row.present,
          row.holiday,
          row.earnedLeaves,
          index
        )) +
        (rowStates[index]?.basicPM ??
          calculateCEILING(
            row.basicPerMonth,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.basicPD ??
          calculateCEILING(
            row.basicPerDay,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.allowancePM ??
          calculateCEILING(
            row.allowancePerMonth,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.hra ??
          calculateCEILING(
            row.hra,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
        (rowStates[index]?.otRate ??
          calculateOtrate(row.otRatePerHour, row.othours, index)) -
        ((rowStates[index]?.pf ??
          calculateCEILING(
            row.pf,
            row.days,
            row.present,
            row.holiday,
            row.earnedLeaves,
            index
          )) +
          (rowStates[index]?.esic ??
            calculateCEILING(
              row.esic,
              row.days,
              row.present,
              row.holiday,
              row.earnedLeaves,
              index
            )) +
          (rowStates[index]?.red ??
            calculateCEILING(
              row.red,
              row.days,
              row.present,
              row.holiday,
              row.earnedLeaves,
              index
            )) +
          (rowStates[index]?.rot ??
            calculateCEILING(
              row.rot,
              row.days,
              row.present,
              row.holiday,
              row.earnedLeaves,
              index
            ))),
    ]);

    // Combine the headers and data
    const combinedData = [...headers, ...data];

    const worksheet = XLSX.utils.aoa_to_sheet(combinedData);

    // Define merges and alignments
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 0, c: 7 } },
      { s: { r: 0, c: 8 }, e: { r: 0, c: 14 } },
      { s: { r: 0, c: 15 }, e: { r: 0, c: 19 } },
      { s: { r: 0, c: 20 }, e: { r: 0, c: 21 } },
    ];

    worksheet["!merges"] = merges;

    merges.forEach((merge) => {
      const startCell = XLSX.utils.encode_cell({ r: merge.s.r, c: merge.s.c });
      worksheet[startCell].s = {
        alignment: { vertical: "center", horizontal: "center" },
      };
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  const calculateOtrate = (basicPerMonth, othours, index) => {
    const result = basicPerMonth * (othours ? othours : 0);
    return Math.ceil(result);
  };
  const handleExportSingle = (
    employee,
    basicPerDay,
    basicPerMonth,
    vda,
    allowance,
    otRate,
    hra,
    pf,
    esic,
    red,
    rot
  ) => {
    console.log(employee);
    let GrossTotal =
      basicPerMonth + basicPerDay + otRate + allowance + hra + vda;
    let Deduction = pf + esic + red + rot;
    const employeeData = `
      <html>
        <head>
          <style>
            body {
              font-size: 8px; 
              padding: 5px;
              margin: 0;
            }
            #row1 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
    
            table {
              width: 100%;
              border-collapse: collapse;
              font-size: 6px; 
            }
    
            th, td {
              padding: 2px; 
              text-align: center;
              border: 1px solid #ddd;
              white-space: normal; 
              word-wrap: break-word; 
            }
    
            th {
              font-weight: bold;
            }
    
            .page-break {
              page-break-before: always;
            }
          </style>
        </head>
        <body>
          <div class="table-container">
            <div id="row1">
              <h5>ASPOLITE ENGINEERS (I) PVT.LTD</h5>
              <h3>WAGE SLIP FOR THE MONTH OF JANUARY - 2024</h3>
              <h5>FORM XIX</h5>
            </div>
            <div id="row1">
              <h5>SO:MAZAGON DOCK</h5>
              <h5>[Rule 78(1)(b)]</h5>
            </div>
            <div style="overflow: auto;">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">Employee Details</th>
                    <th colspan="5">Attendance</th>
                    <th colspan="7">Additions</th>
                    <th colspan="5">Deductions</th>
                    <th>Net Salary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Employee Code</th>
                    <th>Employee Name</th>
                    <th>Days</th>
                    <th>Present</th>
                    <th>Holiday</th>
                    <th>EL</th>
                    <th>OT Hours</th>
                    <th>Basic Per Month</th>
                    <th>Basic Per Day</th>
                    <th>OTRate Per Hour</th>
                    <th>Allowance</th>
                    <th>HRA</th>
                    <th>VDA</th>
                    <th>GrossTotal</th>
                    <th>PF</th>
                    <th>ESIC</th>
                    <th>Retention</th>
                    <th>RetentionOT</th>
                    <th>DetuctionTotal</th>
                    <th>NetSalary</th>
                  </tr>
                  <tr>
                    <td>${employee.empCode}</td>
                    <td>${employee.empName}</td>
                    <td>${employee.days}</td>
                    <td>${employee.present}</td>
                    <td>${employee.holiday}</td>
                    <td>${employee.earnedLeaves}</td>
                    <td>${employee.othours}</td>
                    <td>${basicPerMonth}</td>
                    <td>${basicPerDay}</td>
                    <td>${otRate}</td>
                    <td>${allowance}</td>
                    <td>${hra}</td>
                    <td>${vda}</td>
                    <td>${GrossTotal}</td>
                    <td>${pf}</td>
                    <td>${esic}</td>
                    <td>${red}</td>
                    <td>${rot}</td>
                    <td>${Deduction}</td>
                    <td>${GrossTotal - Deduction}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </body>
      </html>
    `;

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `Payslip_ ${employee.empCode}.pdf`,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(employeeData).set(opt).save();
  };

  const handleExport = (employee) => {
    let GrossTotal =
      employee.basicPerMonth +
      employee.basicPerDay +
      employee.otRatePerHour +
      employee.allowancePerMonth +
      employee.hra +
      employee.vda;
    let Deduction = employee.pf + employee.esic + employee.red + employee.rot;
    const employeeData = `
      <html>
        <head>
          <style>
            body {
              font-size: 8px; 
              padding: 5px;
              margin: 0;
            }
            #row1 {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
    
            table {
              width: 100%;
              border-collapse: collapse;
              font-size: 6px; 
            }
    
            th, td {
              padding: 2px; 
              text-align: center;
              border: 1px solid #ddd;
              white-space: normal; 
              word-wrap: break-word; 
            }
    
            th {
              font-weight: bold;
            }
    
            .page-break {
              page-break-before: always;
            }
          </style>
        </head>
        <body>
          <div class="table-container">
            <div id="row1">
              <h5>ASPOLITE ENGINEERS (I) PVT.LTD</h5>
              <h3>WAGE SLIP FOR THE MONTH OF JANUARY - 2024</h3>
              <h5>FORM XIX</h5>
            </div>
            <div id="row1">
              <h5>SO:MAZAGON DOCK</h5>
              <h5>[Rule 78(1)(b)]</h5>
            </div>
            <div style="overflow: auto;">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">Employee Details</th>
                    <th colspan="5">Attendance</th>
                    <th colspan="7">Additions</th>
                    <th colspan="5">Deductions</th>
                    <th>Net Salary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Employee Code</th>
                    <th>Employee Name</th>
                    <th>Days</th>
                    <th>Present</th>
                    <th>Holiday</th>
                    <th>EL</th>
                    <th>OT Hours</th>
                    <th>Basic Per Month</th>
                    <th>Basic Per Day</th>
                    <th>OTRate Per Hour</th>
                    <th>Allowance</th>
                    <th>HRA</th>
                    <th>VDA</th>
                    <th>GrossTotal</th>
                    <th>PF</th>
                    <th>ESIC</th>
                    <th>Retention</th>
                    <th>RetentionOT</th>
                    <th>DetuctionTotal</th>
                    <th>NetSalary</th>
                  </tr>
                  <tr>
                    <td>${employee.empCode}</td>
                    <td>${employee.empName}</td>
                    <td>${employee.days}</td>
                    <td>${employee.present}</td>
                    <td>${employee.holiday}</td>
                    <td>${employee.earnedLeaves}</td>
                    <td>${employee.othours}</td>
                    <td>${employee.basicPerMonth}</td>
                    <td>${employee.basicPerDay}</td>
                    <td>${employee.otRatePerHour}</td>
                    <td>${employee.allowancePerMonth}</td>
                    <td>${employee.hra}</td>
                    <td>${employee.vda}</td>
                    <td>${GrossTotal}</td>
                    <td>${employee.pf}</td>
                    <td>${employee.esic}</td>
                    <td>${employee.red}</td>
                    <td>${employee.rot}</td>
                    <td>${Deduction}</td>
                    <td>${GrossTotal - Deduction}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </body>
      </html>
    `;

    return html2pdf().from(employeeData).output("blob");
  };
  const handleDownload = async () => {
    const zip = new JSZip();
    console.log(selectedRows);
    const pdfPromises = selectedRows.map(async (employee) => {
      const pdfBlob = await handleExport(employee);
      zip.file(`Payslip_${employee.empCode}.pdf`, pdfBlob);
    });

    await Promise.all(pdfPromises);

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "Payslip.zip");
  };
  return (
    <div>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          component="main"
          id="boxmain"
          sx={{ flexGrow: 1, p: 3, overflow: "hidden" }}
        >
          <div>
            <h1>Payroll Detail</h1>
          </div>
          <div id="crnbody">
            <div id="row2">
              <div id="empbox">
                <label>Location:{locaionName}</label>
              </div>
              <div id="empbox">
                <label>Starting Date : {fromDate}</label>
              </div>
              <div id="empbox">
                <label>Ending Date : {toDate}</label>
              </div>
            </div>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440, overflowY: "auto" }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const allRows = isChecked ? rows : [];
                            setSelectedRows(allRows);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        Employee Details
                      </TableCell>
                      <TableCell
                        colSpan={5}
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        Attendence
                      </TableCell>
                      <TableCell
                        colSpan={7}
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        Additions
                      </TableCell>
                      <TableCell
                        colSpan={5}
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        Deductions
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        NetSalary
                      </TableCell>
                      <TableCell
                        colSpan={1}
                        style={{
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            backgroundColor: " rgb(26 68 127 / 80%)",
                            color: "white",
                            fontWeight: "bold",
                            border: "1px solid #ddd",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.length > 0 ? (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isIssued = row.issued === true;
                          console.log(row)
                          const serialNumber = page * rowsPerPage + index + 1;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              <TableCell align="center">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedRows.includes(row) || isIssued
                                  }
                                  disabled={isIssued}
                                  onChange={(e) => handleCheckboxChange(e, row)}
                                />
                              </TableCell>

                              <TableCell align="center">
                                {serialNumber}
                              </TableCell>
                              <TableCell align="center">
                                {row.empCode}
                              </TableCell>
                              <TableCell align="center">
                                {row.empName}
                              </TableCell>
                              <TableCell align="center">{row.status}</TableCell>
                              <TableCell align="center">{row.days}</TableCell>
                              <TableCell align="center">
                                {row.present}
                              </TableCell>
                              <TableCell align="center">
                                {row.holiday}
                              </TableCell>
                              <TableCell align="center">
                                {row.earnedLeaves}
                              </TableCell>
                              <TableCell align="center">
                                {row.othours}
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.basicPerMonth}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.basicPM ??
                                      calculateCEILING(
                                        row.basicPerMonth,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "basicPM",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.basicPerDay}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.basicPD ??
                                      calculateCEILING(
                                        row.basicPerDay,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "basicPD",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.otRatePerHour}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.otRate ??
                                      calculateOtrate(
                                        row.otRatePerHour,
                                        row.othours,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "otRate",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>

                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.allowancePerMonth}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.allowancePM ??
                                      calculateCEILING(
                                        row.allowancePerMonth,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "allowancePM",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.hra}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.hra ??
                                      calculateCEILING(
                                        row.hra,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "hra",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.vda}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.vda ??
                                      calculateCEILING(
                                        row.vda,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "vda",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>

                              <TableCell align="center">
                                {(rowStates[index]?.vda ??
                                  calculateCEILING(
                                    row.vda,
                                    row.days,
                                    row.present,
                                    row.holiday,
                                    row.earnedLeaves,
                                    index
                                  )) +
                                  (rowStates[index]?.basicPM ??
                                    calculateCEILING(
                                      row.basicPerMonth,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.basicPD ??
                                    calculateCEILING(
                                      row.basicPerDay,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.allowancePM ??
                                    calculateCEILING(
                                      row.allowancePerMonth,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.hra ??
                                    calculateCEILING(
                                      row.hra,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.otRate ??
                                    calculateOtrate(
                                      row.otRatePerHour,
                                      row.othours,
                                      index
                                    ))}
                              </TableCell>

                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.pf}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.pf ??
                                      calculateCEILING(
                                        row.pf,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "pf",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.esic}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.esic ??
                                      row.code === "MWORK"
                                        ? calculateEsicCEILING(
                                            row.esic,
                                            row.days,
                                            row.present,
                                            row.holiday,
                                            row.othours,
                                            row.earnedLeaves,
                                            index
                                          )
                                        : calculateCEILING(
                                            row.red,
                                            row.days,
                                            row.present,
                                            row.holiday,
                                            row.earnedLeaves,
                                            index
                                          )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "esic",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>

                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.red}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.red ??
                                      calculateCEILING(
                                        row.red,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "red",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {row.rot}
                                  </span>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <TextField
                                    type="number"
                                    value={
                                      rowStates[index]?.rot ??
                                      calculateCEILING(
                                        row.rot,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )
                                    }
                                    disabled={isIssued}
                                    onChange={(e) => {
                                      updateRowState(
                                        index,
                                        "rot",
                                        parseFloat(e.target.value)
                                      );
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                {(rowStates[index]?.pf ??
                                  calculateCEILING(
                                    row.pf,
                                    row.days,
                                    row.present,
                                    row.holiday,
                                    row.earnedLeaves,
                                    index
                                  )) +
                                  (rowStates[index]?.esic ??
                                    calculateCEILING(
                                      row.esic,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.red ??
                                    calculateCEILING(
                                      row.red,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.rot ??
                                    calculateCEILING(
                                      row.rot,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    ))}
                              </TableCell>
                              <TableCell align="center">
                                {/* {additions - deductions} */}
                                {(rowStates[index]?.vda ??
                                  calculateCEILING(
                                    row.vda,
                                    row.days,
                                    row.present,
                                    row.holiday,
                                    row.earnedLeaves,
                                    index
                                  )) +
                                  (rowStates[index]?.basicPM ??
                                    calculateCEILING(
                                      row.basicPerMonth,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.basicPD ??
                                    calculateCEILING(
                                      row.basicPerDay,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.allowancePM ??
                                    calculateCEILING(
                                      row.allowancePerMonth,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.hra ??
                                    calculateCEILING(
                                      row.hra,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                  (rowStates[index]?.otRate ??
                                    calculateOtrate(
                                      row.otRatePerHour,
                                      row.othours,
                                      index
                                    )) -
                                  ((rowStates[index]?.pf ??
                                    calculateCEILING(
                                      row.pf,
                                      row.days,
                                      row.present,
                                      row.holiday,
                                      row.earnedLeaves,
                                      index
                                    )) +
                                    (rowStates[index]?.esic ??
                                      calculateCEILING(
                                        row.esic,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )) +
                                    (rowStates[index]?.red ??
                                      calculateCEILING(
                                        row.red,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )) +
                                    (rowStates[index]?.rot ??
                                      calculateCEILING(
                                        row.rot,
                                        row.days,
                                        row.present,
                                        row.holiday,
                                        row.earnedLeaves,
                                        index
                                      )))}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handleExportSingle(
                                      row,
                                      rowStates[index]?.basicPD ??
                                        calculateCEILING(
                                          row.basicPerDay,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.basicPM ??
                                        calculateCEILING(
                                          row.basicPerMonth,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.vda ??
                                        calculateCEILING(
                                          row.vda,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.allowancePM ??
                                        calculateCEILING(
                                          row.allowancePerMonth,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.otRate ??
                                        calculateOtrate(
                                          row.otRatePerHour,
                                          row.othours,
                                          index
                                        ),
                                      rowStates[index]?.hra ??
                                        calculateCEILING(
                                          row.hra,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.pf ??
                                        calculateCEILING(
                                          row.pf,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.esic ??
                                        calculateCEILING(
                                          row.esic,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.red ??
                                        calculateCEILING(
                                          row.red,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        ),
                                      rowStates[index]?.rot ??
                                        calculateCEILING(
                                          row.rot,
                                          row.days,
                                          row.present,
                                          row.holiday,
                                          row.earnedLeaves,
                                          index
                                        )
                                    )
                                  }
                                >
                                  Download
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows && rows.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  padding: "5px",
                }}
              >
                <Button
                  variant="contained"
                  className="subbtn"
                  onClick={handleBack}
                >
                  <ArrowBackIcon />
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  style={{ marginLeft: "10px" }}
                  onClick={handleDownload}
                >
                  Download
                  <DownloadIcon />
                </Button>

                <Button
                  variant="contained"
                  className="success"
                  style={{ marginLeft: "10px" }}
                  color="secondary"
                  onClick={handleExportData}
                >
                  Export
                  <SaveIcon />
                </Button>
                <Button
                  variant="contained"
                  className="success"
                  style={{ marginLeft: "10px" }}
                  color="success"
                  onClick={handleSave}
                >
                  Save
                  <SaveIcon />
                </Button>
              </div>
            </Paper>
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            id="dialog"
            // scroll={scroll}
          >
            <DialogTitle>Employee Detail</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {employee ? (
                  <>
                    <div id="empdailog">
                      <span>{employee.empName}</span>
                      <span>{employee.empCode}</span>
                    </div>
                    <div id="empdailog">
                      <span>Days</span>
                      <span>{employee.days}</span>
                    </div>
                    <div id="empdailog">
                      <span>Prsent Days</span>
                      <span>{employee.present}</span>
                    </div>
                    <div id="empdailog">
                      <span>Holidays</span>
                      <span>{employee.holiday}</span>
                    </div>{" "}
                    <div id="empdailog">
                      <span>Basic Per Month</span>
                      <span>
                        {Math.ceil(
                          (employee.basicPerMonth / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>Basic Per Day</span>
                      <span>{employee.basicPerDay}</span>
                    </div>
                    <div id="empdailog">
                      <span>Allowance Per Month</span>
                      <span>
                        {Math.ceil(
                          (employee.basicPerDay / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>OT Rate Per Hour</span>
                      <span>{employee.otRatePerHour * employee.othours}</span>
                    </div>
                    <div id="empdailog">
                      <span>VDA</span>
                      <span>
                        {Math.ceil(
                          (employee.vda / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>HRA</span>
                      <span>
                        {Math.ceil(
                          (employee.hra / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>Gross</span>
                      <span>
                        {Math.ceil(
                          (employee.hra / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        ) +
                          Math.ceil(
                            (employee.vda / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          Math.ceil(
                            (employee.basicPerMonth / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          Math.ceil(
                            (employee.basicPerDay / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          Math.ceil(
                            (employee.allowancePerMonth / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          employee.otRatePerHour * employee.othours}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>PF</span>
                      <span>
                        {Math.ceil(
                          (employee.pf / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>ESIC</span>
                      <span>
                        {Math.ceil(
                          (employee.esic / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>{" "}
                    <div id="empdailog">
                      <span>Retention</span>
                      <span>
                        {Math.ceil(
                          (employee.red / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>{" "}
                    <div id="empdailog">
                      <span>Retention ot</span>
                      <span>
                        {Math.ceil(
                          (employee.rot / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        )}
                      </span>
                    </div>
                    <div id="empdailog">
                      <span>Net Salary</span>
                      <span>
                        {Math.ceil(
                          (employee.hra / employee.days) *
                            (employee.present +
                              employee.holiday +
                              employee.earnedLeaves)
                        ) +
                          Math.ceil(
                            (employee.vda / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          Math.ceil(
                            (employee.basicPerMonth / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          Math.ceil(
                            (employee.basicPerDay / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          Math.ceil(
                            (employee.allowancePerMonth / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                          employee.otRatePerHour * employee.othours -
                          (Math.ceil(
                            (employee.pf / employee.days) *
                              (employee.present +
                                employee.holiday +
                                employee.earnedLeaves)
                          ) +
                            Math.ceil(
                              (employee.esic / employee.days) *
                                (employee.present +
                                  employee.holiday +
                                  employee.earnedLeaves)
                            ) +
                            Math.ceil(
                              (employee.red / employee.days) *
                                (employee.present +
                                  employee.holiday +
                                  employee.earnedLeaves)
                            ) +
                            Math.ceil(
                              (employee.rot / employee.days) *
                                (employee.present +
                                  employee.holiday +
                                  employee.earnedLeaves)
                            ))}
                      </span>
                    </div>
                  </>
                ) : (
                  "employee not found"
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => handleExportSingle(employee)}>
                Download
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </div>
  );
}

export default ViewPayroll;
