import React from "react";
import Sidebar from "../components/sidebar";
import Box from "@mui/material/Box";
import Navbar from "../components/navbar";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import "../screens/screens.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import DangerousIcon from '@mui/icons-material/Dangerous';
import TableHead from "@mui/material/TableHead";
import FilterListIcon from '@mui/icons-material/FilterList';
import TablePagination from "@mui/material/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import TableRow from "@mui/material/TableRow";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Select,FormControl, InputLabel } from "@mui/material";
const columns = [
  { id: "SrNo", label: "Sr No", align: "center" },
  { id: "FistName", label: "First Name", align: "center" },
  { id: "LastName", label: "Last Name", align: "center" },
  { id: "empCode", label: "Employee Code", 
  minWidth: 50,
  align: "center" },
  {
    id: "BaseLocation",
    label: "BaseLocation",
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "DOB",
    label: "DOB",
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "DOJ",
    label: "DOJ",
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Status",
    label: "Status",
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Action",
    label: "Action",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const URL = "https://api.aspolite.com/api/employee/GetUserList";
const URL1 = "https://api.aspolite.com/api/employee/getuser";
const URL2 = "https://api.aspolite.com/api/report/location"
function Employee() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [loading, setLoading] = useState(true);
const [filterPro, setFilterPro] = useState([]);
const [options, setOptions] = useState([]);
const [isSearch, setIsSearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [status, setStatus] = useState('');
  const [location, setLocation] = useState('');

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearch = (e) => {
    let val = e.target.value.toLowerCase(); 
    let fltData = rows.filter(
      (pro) => 
        (pro.firstName && pro.firstName.toLowerCase().includes(val)) || 
        (pro.lastName && pro.lastName.toLowerCase().includes(val))
    );
    setFilterPro(fltData);
    setIsSearch(val ? true : false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const token = localStorage.getItem("token");
  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRows(res.data);
      setLoading(false)
      if (res.data.length > 5) {
        setRowsPerPage(7);
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);

  
  
  const fetchHandlerLocation = async () => {
    try {
      const res = await axios.get(URL2, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandlerLocation();
  }, []);
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleApply = () => {
    let filteredData = rows;
    if (status) {
      filteredData = filteredData.filter((row) => row.status.toLowerCase() === status.toLowerCase());
    }
    if (location) {
      filteredData = filteredData.filter((row) => row.name.toLowerCase() === location.toLowerCase());
    }
    setFilterPro(filteredData);
    setIsSearch(true);
    handleCloseMenu();
  };

  const handleEdit = (id) => {
    const selectedShift = rows.find((row) => row.id === id);
    const fetchHandler1= async () => {
      try {
        const res = await axios.get(`${URL1}?UserId=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const selectedData = res.data[0]
        setLoading(false)
        navigate("/empRegistration", { state:{ selectedData }})
    
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false)
      }
    };
      fetchHandler1();
 
  };

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpen(true);
  };
const handleExit = () => {
  navigate("/exitEmployee")
}
  const handleDeleteConfirm = async () => {
    setOpen(false);
    try {
      await axios.post(
        `https://apiprojexssl.iflotech.in/api/employee/DeleteUser?UserId=${deleteId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRows((prows) => prows.filter((row) => row.id !== deleteId));
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleClose = () => {
  setOpen(false)
  }
  const dataToDisplay = isSearch ? filterPro : rows;
  return (
    <div>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
        <div id="headrs">
        <div>
            <h1>Employees Details</h1>
          </div>
          <div className="search">
            <SearchIcon />
            <input
              type="text"
              className="search_input"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <div>
            <Button variant="outlined" onClick={handleClick}>Filter & Sort <FilterListIcon/></Button>
          </div>
             </div>
         
          {loading ? (
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop> 
          ) :
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer >
              <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
  <TableRow>
    {columns.map((column) => (
      <TableCell
        key={column.id}
        align={column.align}
        style={{
          minWidth: column.minWidth,
          backgroundColor: '#1565c0', 
          color: 'white', 
          fontWeight: 'bold', 
        }}
      >
        {column.label}
      </TableCell>
    ))}
  </TableRow>
</TableHead>
                <TableBody>
                  {dataToDisplay && dataToDisplay.length > 0 ? (
                    dataToDisplay
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row ,index) => {
                        const serialNumber = page * rowsPerPage + index + 1;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {/* {columns.map((column) => (
                              <TableCell key={column.id} align={column.align}>
                                {row[column.id]}
                              </TableCell>
                            ))} */}
                            <TableCell align="center">
                              {serialNumber}
                            </TableCell>   <TableCell align="center">
                              {row.firstName}
                            </TableCell>
                            <TableCell align="center">{row.lastName}</TableCell>
                            <TableCell align="center">{row.empCode}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.dob}</TableCell>
                            <TableCell align="center">{row.doj}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center" id="contrls">
                            <Button variant="contained" onClick={handleExit}>Exit</Button>
                              <Button
                                variant="text"
                                onClick={() => handleEdit(row.id)}
                              >
                                <img
                                  src="./images/icons8-edit-30.png"
                                  alt="edit"
                                  width="25px"
                                  height="25px"
                                />
                              </Button>
                              <Button
                                variant="text"
                                onClick={() => handleDelete(row.id)}
                              >
                                <img
                                  src="./images/icons8-delete-30.png"
                                  alt="edit"
                                  width="25px"
                                  height="25px"
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {rows && rows.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5,6,25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
}<Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="responsive-dialog-title"
  sx={{
    "& .MuiDialog-paper": {
      height: "auto",
      maxWidth: "none",
    },
  }}
>
  <DialogTitle id="responsive-dialog-title">Confirm Deletion</DialogTitle>
  <DialogContent dividers>
    <DialogContentText sx={{ textAlign: "center" }}>
    <DangerousIcon  style={{color:"red"}}/> <br/>
      Are you sure you would like to delete the employee?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button autoFocus variant="contained" onClick={handleClose}>
      Cancel
    </Button>
    <Button
      onClick={handleDeleteConfirm}
      variant="contained"
      autoFocus
      color="error"
    >
      Delete
    </Button>
  </DialogActions>
</Dialog>

<Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth style={{display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"space-between"}}>
            <label>Status</label>
            <Select
              value={status}
              onChange={handleStatusChange}
              displayEmpty
              size="small"
              sx={{paddingRight:"30px !important"}}
            >
              <MenuItem value="" disabled>Select Status</MenuItem>
              <MenuItem value={'accepted'}>Accepted</MenuItem>
              <MenuItem value={'rejected'}>Rejected</MenuItem>
              <MenuItem value={'onhold'}>On Hold</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{display:"flex",alignItems:"center",flexDirection:"row",justifyContent:"space-between"}}>
            <label>Location</label>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
             
              value={location}
              onChange={handleLocationChange}
              displayEmpty
              size="small"
              sx={{marginLeft:"5px",paddingRight:"30px !important"}}
            >
            <MenuItem value="" disabled>Select location</MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Button onClick={handleApply} variant="contained" color="primary">
            APPLY
          </Button>
        </Box>
      </Menu>
          <div id="cretebtn">
        
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate("/empRegistration");
              }}
            >
              Add New
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Employee;
