import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const URL = "https://api.aspolite.com/api/master/location";
function CreateNew() {
  const location = useLocation();
  const  data  = location.state;
  const shiftData = data && data.selectedShift ? data.selectedShift : {};

  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [name, setName] = useState(shiftData.id ? shiftData.name : "");
  const [code, setCode] = useState(shiftData.id ? shiftData.code : "");
  const [status, setStatus] = useState(shiftData.id ? (shiftData.status === "Active" ? 1 : 0) : "");
  const [startTime, setStartTime] = useState(shiftData.id ? dayjs(shiftData.startTime, "HH:mm") : "");
  const [endTime, setEndTime] = useState(shiftData.id ? dayjs(shiftData.endTime, "HH:mm") : "");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLocationId(e.target.value);
  };
  const token = localStorage.getItem("token");

  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {

      fetchHandler();
    
  }, []);
  useEffect(() => {
    if (shiftData.id && options.length > 0) {
        const selectedOption = options.find(option => shiftData.location === option.name);
        if (selectedOption) {
            setLocationId(selectedOption.id);
        }
    }
}, [shiftData, options]);
const handleBack = () => {
  navigate("/shift")
}
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      
      if (shiftData.id) {
        const res = await axios.patch(
          `https://apiprojexssl.iflotech.in/api/master/shift/`,
          {
            id:shiftData.id,
            name,
            code,
         locationId,
            status,
            startTime: startTime ? startTime.format("HH:mm") : null,
            endTime: endTime ? endTime.format("HH:mm") : null,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        ;
        navigate("/shift");
      } else {
        const res = await axios.post(
          "https://apiprojexssl.iflotech.in/api/master/shift",
          {
            name,
            code,
            locationId,
            status,
            startTime: startTime ? startTime.format("HH:mm") : null,
            endTime: endTime ? endTime.format("HH:mm") : null,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        navigate("/shift");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  }

  const calculateDuration = () => {
    if (startTime && endTime) {
      const duration = endTime.diff(startTime, "minutes");
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return `${hours}h ${minutes}m`;
    }
    return "";
  };
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>{shiftData.id ? "Edit Shift" : "Create Shift"}</h1>
          </div>
          <div id="crnbody">
            <div id="firstn">
              <label>Name</label>
              <TextField
                id="outlined-basic"
                className="txtfield"
                size="small"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div id="row2">
              <div id="firstn">
                <label>Code</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  placeholder="Enter Code"
                  fullWidth
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </div>
              <div id="firstn">
                <label>Location</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={locationId}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Select Location</em>
                  </MenuItem>
                  {options.map((option) => (
    <MenuItem key={option.id} value={option.id}>
        {option.name}
    </MenuItem>
))}


                </Select>
              </div>
              <div id="statusn">
                <label>Status</label>
                <Select
                  // onChange={handleChange}
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Select Status</em>
                  </MenuItem>
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Pending</MenuItem>
                </Select>
              </div>
            </div>

            <div id="row2">
              <div id="firstn">
                <label>Start Time</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["TimePicker"]}
                    size="small !important"
                    fullWidth
                  >
                    <TimePicker
                      placeholder="StartTime"
                      value={startTime}
                      onChange={(newValue) => setStartTime(newValue)}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="firstn">
                <label>End Time</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                  <DemoContainer
                    components={["TimePicker"]}
                    size="small !important"
                    fullWidth
                  >
                    <TimePicker
                      placeholder="StartTime"
                      onChange={(newValue) => setEndTime(newValue)}
                      value={endTime}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="statusn">
                <label>Duration</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  placeholder="Duration"
                  fullWidth
                  style={{ marginRight: "0px !important" }}
                  value={calculateDuration()}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
          <Button variant="contained" className="subbtn" onClick={handleBack} 
            style={{ position: "fixed", bottom: "16px", right: "140px", marginLeft:"10px" }}
            >
            <ArrowBackIcon />
            Back
          </Button>
          <Button
            variant="contained"
            className="subbtn"
            color="success"
            onClick={handleSubmit}
            style={{ position: "fixed", bottom: "16px", right: "16px", marginLeft:"10px" }}
          >
            Submit
            <NavigateNextIcon/>
          </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default CreateNew;
