import React from "react";
import Sidebar from "../components/sidebar";
import Box from "@mui/material/Box";
import Navbar from "../components/navbar";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import "../screens/screens.css";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";

function Reguralization() {
  return (
    <div>    
          <Navbar />
    <Box sx={{ display: "flex" }}>
      <Sidebar />           
      <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
        <div>
          <h1>In Second Phase Will Continue</h1>
        </div>
        </Box>
        </Box>
        </div>
  )
}

export default Reguralization