import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Textarea from "@mui/joy/Textarea";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useLocation } from "react-router-dom";
import { grey, lightGreen } from "@mui/material/colors";

const steps = [
  "Initial Details",
  "KYC details",
  "KYC documents",
  "Verification",
  "Configuration",
];
const URL = "https://api.aspolite.com/api/master/designation";
const URL1 = "https://api.aspolite.com/api/master/shift";
const URL2 = "https://api.aspolite.com/api/master/wage";
const URL3 = "https://api.aspolite.com/api/employee/getuser";
function EmpConfiguration() {
  const location = useLocation();
  const data = location.state;
  const employeeData = data ? data.employeeData : null;
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [esic,setEsic] = useState("")
  const [selectedDesignation, setSelectedDesignation] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.designationId
      : ""
  );
  const [designationSelected, setDesignationSelected] = useState(null);
  const [selectedShift, setSelectedShift] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.shiftId
      : ""
  );
  const [description, setDescription] = useState("");
  const [comment, setComment] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.designationComments
      : ""
  );
  const [shiftComment, setShiftComment] = useState(
    employeeData && employeeData.length
      ? employeeData.userConfigurations.shiftComments
      : ""
  );
  const [shiftOptions, setShiftOptions] = useState([]);
  const [WageOptions, setWageOptions] = useState([]);
  const [wageSelect, setWageSelect] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.wageId
      : ""
  );
  const [selectedWageOption, setSelectedWageOption] = useState(null);
  const [editedBasicPerDay, setEditedBasicPerDay] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.basicPerDay
      : ""
  );
  const [editedBasicPerMonth, setEditedBasicPerMonth] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.basicPerMonth
      : ""
  );
  const [editedOTRatePerHour, setEditedOTRatePerHour] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.otRatePerHour
      : ""
  );
  const [editedAlowancePerMonth, setEditedAllowancePerMonth] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.allowancePerMonth
      : ""
  );
  const [editedVda, setEditedVda] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.vda
      : ""
  );
  const [editedRetention, setEditedRetention] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.red
      : ""
  );
  const [pfValue, setPFValue] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.pf
      : ""
  );
  const [hraVlaue, setHraValue] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.hra
      : ""
  );
  const [redot, setRedOt] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.rot
      : 0
  );
  const [esicValue, setEsicValue] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.esic
      : 0
  );
  const [empCode, setEmpCode] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.empCode
      : ""
  );
  const [earned, setEarned] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.designationId
      : 0
  );
  const [ot, setOt] = useState(
    employeeData && employeeData.userConfigurations
      ? employeeData.userConfigurations.designationId
      : 0
  );
  const token = localStorage.getItem("token");
  const userId = parseInt(localStorage.getItem("userId"), 10);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (employeeData && employeeData.userConfigurations) {
        const res = await axios.post(
          `https://api.aspolite.com/api/employee/configuration?userId=${employeeData.userId}`,
          {
            userId: employeeData.userId,
            EmpCode: empCode,
            Designation: selectedDesignation,
            DesignationComments: comment,
            Shift: selectedShift,
            ShiftComments: shiftComment,
            wage: wageSelect,
            BasicPerDay: editedBasicPerDay || selectedWageOption?.basicPerDay,
            BasicPerMonth:
              editedBasicPerMonth || selectedWageOption?.basicPerMonth,
            OtRatePerHour:
              editedOTRatePerHour || selectedWageOption?.otRatePerHour,
            AllowancePerMonth:
              editedAlowancePerMonth || selectedWageOption?.allowancePerMonth,
            Vda: editedVda || selectedWageOption?.vda,
            Hra: hraVlaue || selectedWageOption?.hra,
            Red: editedRetention || selectedWageOption?.red,
            Rot: redot || selectedWageOption?.rot,
            Pf: pfValue || selectedWageOption?.pf,
            Esic: esicValue,
            Earned: earned,
            OT: ot,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        navigate("/employee");
      } else {
        const res = await axios.post(
          "https://api.aspolite.com/api/employee/configuration",
          {
            userId: userId,
            EmpCode: empCode,
            Designation: selectedDesignation,
            DesignationComments: comment,
            Shift: selectedShift,
            ShiftComments: shiftComment,
            wage: wageSelect,
            BasicPerDay: editedBasicPerDay || selectedWageOption?.basicPerDay,
            BasicPerMonth:
              editedBasicPerMonth || selectedWageOption?.basicPerMonth,
            OtRatePerHour:
              editedOTRatePerHour || selectedWageOption?.otRatePerHour,
            AllowancePerMonth:
              editedAlowancePerMonth || selectedWageOption?.allowancePerMonth,
            Vda: editedVda || selectedWageOption?.vda,
            Hra: hraVlaue || selectedWageOption?.hra,
            Red: editedRetention || selectedWageOption?.red,
            Rot: redot || selectedWageOption?.rot,
            Pf: pfValue || selectedWageOption?.pf,
            Esic: esicValue,
            Earned: earned,
            OT: ot,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        navigate("/employee");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while logging in");
    }
  }
  async function hanldePrevious(e) {
    try {
      const res = await axios.get(
        `${URL3}?UserId=${employeeData ? employeeData.userId : userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const selectedData = res.data[0];
      navigate("/empVerification", { state: { selectedData } });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  const fetchHandler = async (url, setOptions) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchHandler(URL, setDesignationOptions);
    fetchHandler(URL1, setShiftOptions);
    fetchHandler(URL2, setWageOptions);
  }, []);

  useEffect(() => {
    const selectedOption = WageOptions.find(
      (option) => option.id === wageSelect
    );
    setSelectedWageOption(selectedOption);
  }, [wageSelect, WageOptions]);
  useEffect(() => {
    const selectedOption = designationOptions.find(
      (option) => option.id === selectedDesignation
    );
    setDesignationSelected(selectedOption);
  }, [designationOptions, selectedDesignation]);
  console.log(selectedWageOption.basicPerDay , editedBasicPerDay)
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmainemp" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h3>Employee Configuration</h3>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={5} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div>
            <div>
              <h3>Designation</h3>
            </div>
            <div id="row2">
              <div id="empbodycon">
                <label>Select Designation</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  displayEmpty
                  className="selLocation"
                  value={selectedDesignation}
                  onChange={(e) => {
                    setSelectedDesignation(e.target.value);
                  }}
                >
                  <MenuItem disabled>
                    <em>Select Designation</em>
                  </MenuItem>
                  {designationOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div id="empcode">
                <label>Employee Code</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  value={empCode}
                  onChange={(e) => setEmpCode(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label>Description</label>
              {designationSelected && (
                <Textarea
                  placeholder="Type anything…"
                  onChange={(e) => setDescription(e.target.value)}
                  value={designationSelected.description}
                />
              )}
            </div>

            <div>
              <label>Comment</label>
              <Textarea
                placeholder="Type anything…"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </div>

          <div>
            <div>
              <h3>Shift</h3>
            </div>
            <div id="empbodycon">
              <label>Select Shift</label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                displayEmpty
                className="selLocation"
                value={selectedShift}
                onChange={(e) => {
                  setSelectedShift(e.target.value);
                }}
              >
                <MenuItem disabled>
                  <em>Select Shift</em>
                </MenuItem>
                {shiftOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div>
              <label>Comment</label>
              <Textarea
                placeholder="Type anything…"
                value={shiftComment}
                onChange={(e) => setShiftComment(e.target.value)}
              />
            </div>
          </div>

          <div>
            <div>
              <h3>Wage</h3>
            </div>
            <div id="empbodycon">
              <label>Select </label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                displayEmpty
                className="selLocation"
                value={wageSelect}
                onChange={(e) => {
                  setWageSelect(e.target.value);
                }}
              >
                <MenuItem disabled>
                  <em>Select Wage</em>
                </MenuItem>
                {WageOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {selectedWageOption && (
              <div>
                <div id="row2">
                  <div id="empbox">
                    <label>Basic Per Day</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={
                        editedBasicPerDay !== ""
                          ? editedBasicPerDay
                          : selectedWageOption.basicPerDay
                      }
                      onChange={(e) => setEditedBasicPerDay(e.target.value)}
                    />
                  </div>
                  <div id="empbox">
                    <label>Basic Per Month</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={
                        editedBasicPerMonth !== ""
                          ? editedBasicPerMonth
                          : selectedWageOption.basicPerMonth
                      }
                      onChange={(e) => setEditedBasicPerMonth(e.target.value)}
                    />
                  </div>
                  <div id="empbox">
                    <label>OT Rate Per Hour</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={
                        editedOTRatePerHour !== ""
                          ? editedOTRatePerHour
                          : selectedWageOption.otRatePerHour
                      }
                      onChange={(e) => setEditedOTRatePerHour(e.target.value)}
                    />
                  </div>
                  <div id="empbox">
                    <label>Allowance Per Month</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={
                        editedAlowancePerMonth !== ""
                          ? editedAlowancePerMonth
                          : selectedWageOption.allowancePerMonth
                      }
                      onChange={(e) =>
                        setEditedAllowancePerMonth(e.target.value)
                      }
                    />
                  </div>
                  <div id="empbox">
                    <label>VDA</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={
                        editedVda !== "" ? editedVda : selectedWageOption.vda
                      }
                      onChange={(e) => setEditedVda(e.target.value)}

                      // onChange={(e) => {
                      //   setQualification(e.target.value);
                      // }}
                    />
                  </div>
                </div>
                <div id="row2">
                  <div id="empbox">
                    <label>HRA</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      value={
                        hraVlaue !== "" ? hraVlaue : selectedWageOption.hra
                      }
                      onChange={(e) => setHraValue(e.target.value)}
                    />
                  </div>
                  <div id="empbox">
                    <label>Retantion Days</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="number"
                      value={
                        editedRetention !== ""
                          ? editedRetention
                          : selectedWageOption.red
                      }
                      onChange={(e) => setEditedRetention(e.target.value)}
                    />
                  </div>
                  <div id="empbox">
                    <label>Retention OT</label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="number"
                      value={redot !== "" ? redot : selectedWageOption.rot}
                      onChange={(e) => setRedOt(e.target.value)}
                      // onChange={(e) => {
                      //   setQualification(e.target.value);
                      // }}
                    />
                  </div>
                  <div id="empbox" style={{marginTop:"25px"}}>
                    <label>PF (%)
                    
                    </label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      fullWidth
                      variant="outlined"
                      type="number"
                      value={pfValue !== "" ? pfValue : selectedWageOption.pf/(selectedWageOption.basicPerDay || editedBasicPerDay || 0) * 100}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value <= 100) {
                          setPFValue(value);
                        }
                      }}
                      inputProps={{ min: 0, max: 100 }}
                    />
                    <span style={{backgroundColor:"#c9f7ff" ,padding:"2px 5px"}}>{(pfValue * editedBasicPerDay ) /100}</span>
                  </div>

                  <div id="empbox" style={{marginTop:"25px"}}>
                    <label>ESIC (%)
                      
                      </label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={
                        esicValue !== "" ? esicValue : selectedWageOption.esic / (selectedWageOption.basicPerDay || editedBasicPerDay || 0) * 100
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value <= 100) {
                          setEsicValue(value);
                          
                        }
                      }}
                      inputProps={{ min: 0, max: 100 }}
                    />
                    <span style={{backgroundColor:"#c9f7ff" ,padding:"0px 5px"}}>{ ( editedBasicPerDay  * esicValue) / 100  }</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            style={employeeData ? { display: "none" } : { display: "block" }}
          >
            <div>
              <h3>Leaves</h3>
            </div>
            <div id="row">
              <div id="empbox" >
                <label>Earned Leaves</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={earned}
                  disabled={employeeData}
                  onChange={(e) => setEarned(e.target.value)}
                />
              </div>
              <div id="empbox">
                <label>Overtime</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={ot}
                  disabled={employeeData}
                  onChange={(e) => setOt(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Button
              variant="contained"
              className="subbtn"
              color="success"
              onClick={hanldePrevious}
              style={{ marginRight: "10px" }}
            >
              <NavigateBeforeIcon />
              Previous
            </Button>
            <Button
              variant="contained"
              className="subbtn"
              onClick={handleSubmit}
            >
              Submit
              <NavigateNextIcon />
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default EmpConfiguration;
