import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { emphasize, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import DangerousIcon from "@mui/icons-material/Dangerous";
import TableHead from "@mui/material/TableHead";
import SearchIcon from "@mui/icons-material/Search";

import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
function AddBonus() {
  const URL2 = "https://api.aspolite.com/api/bonus/employees";
  const URL1 = "https://api.aspolite.com/api/report/location";
  const URL  = "https://api.aspolite.com/api/bonus/save";
  const location = useLocation();
  const data = location.state;
  console.log(data)
  const editedData = data && data.editedData ? data.editedData : {};
console.log(editedData)
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [fromDate, setFromDate] = useState(
    editedData ? dayjs(editedData[0].fromDate) : dayjs() 
  );
  const [toDate, setToDate] = useState(
    editedData ? dayjs(editedData[0].toDate) : dayjs() 
  );
  const [bonusDate, setBounusDate] = useState(
    editedData ? dayjs(editedData[0].date) : dayjs() 
  );
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [locationName,setLocationName] = useState(editedData ? editedData[0].location :  "");
  const [bonusAmount, setBonusAmount] = useState("");
  const [filterPro, setFilterPro] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const navigate = useNavigate();
console.log(bonusDate)
  const columns = [
    { id: "checkbox", label: "", align: "center" },
    { id: "SrNo", label: "Sr No", align: "center" },
    { id: "EmpCode", label: "Emp Code", align: "center" },
    { id: "EmpName", label: "Emp Name", align: "center" },
    { id: "JoinigDate", label: "Joining Date", minWidth: 50, align: "center" },
    {
      id: "Bonus Issued",
      label: "Bonus Issued",
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "Bonus",
      label: "Bonus Amount",
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChange = (e) => {
    setLocationName(e.target.value);
    let location = options.filter((option) => option.name === e.target.value)
    console.log(location)
    setLocationId(location[0].id);
  };
  const token = localStorage.getItem("token");

  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);

  const handleCheckboxToggle = (row) => {
    if (checkedRows.includes(row)) {
      setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    } else {
      setCheckedRows([...checkedRows, row]);
    }
  };
  console.log(checkedRows)

  const handleEdit = (id) => {
    const selectedShift = rows.find((row) => row.id === id);
    const fetchHandler1 = async () => {
      try {
        const res = await axios.get(`${URL1}?UserId=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const selectedData = res.data[0];
        setLoading(false);
        navigate("/empRegistration", { state: { selectedData } });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchHandler1();
  };

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  useEffect(() => {
   if(editedData && editedData[0]){
    setRows(editedData[0].employees)
   }
  }, [editedData])
  
  const handleloadEmployees = async () => {
    try {
      const res = await axios.get(
        URL2,
       
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRows(res.data);
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  };
 
  const handleBack = () => {
    navigate("/shift");
  };
  async function handleSubmit(e) {
    e.preventDefault();
    const location = options.filter((opt) => opt.id === locationId);
    try {
      console.log(rows)
      const selectedRows = rows.filter((row) => row.checked);
      console.log(selectedRows)

      const selectedEmployees = selectedRows.map((row) => ({
        userId: row.userId,
        empCode: row.empCode,
        empName: row.empName,
        joining: row.joiningDate,
        bonusAmount: row.amount || bonusAmount,
      }));
      const res = await axios.post(
        URL,
        {
          fromDate: fromDate,
          toDate: toDate,
          date: bonusDate,
          locationId: locationId,
          location: locationName,
          Details: checkedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/bonus");
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  }
  const handleSearch = (e) => {
    let val = e.target.value.toLowerCase(); 
    let fltData = rows.filter(
      (pro) => 
        (pro.empName && pro.empName.toLowerCase().includes(val))
    );
    setFilterPro(fltData);
    setIsSearch(val ? true : false);
  };
  const dataToDisplay = isSearch ? filterPro : rows;
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
        <div id="headrs">
        <div>
            <h1>{editedData.id ? "Edit Bonus" : "Add Bonus"}</h1>
           
          </div>
          <div className="search">
            <SearchIcon />
            <input
              type="text"
              className="search_input"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
        </div>
       
         
          <div id="crnbody">
            <div id="row2">
              <div id="empbox">
                <label>Bonus Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      value={bonusDate}
                      onChange={(e) => {
                        setBounusDate(dayjs(e).format('DD-MMM-YYYY') || '');                        
                      }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="firstn">
                <label>Location</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={locationName}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Select Location</em>
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div id="empbox">
                <label>From Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(dayjs(e).format('DD-MMM-YYYY') || '');
                      }}
                       format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <div id="empbox">
                <label>To Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      value={toDate}
                      onChange={(e) => {
                        setToDate(dayjs(e).format('DD-MMM-YYYY') || '');
                      }}
                     format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div id="cretebtn">
            <Button variant="contained" onClick={handleloadEmployees}>
              Load Employees
            </Button>
          </div>
          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            style={
              rows
                ? { display: "block", marginTop: "10px" }
                : { display: "none" }
            }
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {dataToDisplay && dataToDisplay.length > 0 ? (
                    dataToDisplay
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {/* {columns.map((column) => (
                              <TableCell key={column.id} align={column.align}>
                                {row[column.id]}
                              </TableCell>
                            ))} */}
                            <TableCell align="center">
                              <input
                                type="checkbox"
                                checked={checkedRows.includes(row)}
                                onChange={() => handleCheckboxToggle(row)}
                              />
                            </TableCell>
                            <TableCell align="center">{index + 1}</TableCell>{" "}
                            <TableCell align="center">{row.empCode}</TableCell>
                            <TableCell align="center">{row.empName}</TableCell>
                            <TableCell align="center">{row.joining}</TableCell>
                            <TableCell align="center">{row.issued}</TableCell>
                            <TableCell align="center">
                              <TextField
                                type="number"
                                value={row.amount || bonusAmount}
                                onChange={(e) => {
                                  setBonusAmount(e.target.value);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rows && rows.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 6, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              className="subbtn"
              onClick={handleBack}
              style={{
                position: "fixed",
                bottom: "16px",
                right: "140px",
                marginLeft: "10px",
              }}
            >
              <ArrowBackIcon />
              Back
            </Button>
            <Button
              variant="contained"
              className="subbtn"
              color="success"
              onClick={handleSubmit}
              style={{
                position: "fixed",
                bottom: "16px",
                right: "16px",
                marginLeft: "10px",
              }}
            >
              Submit
              <NavigateNextIcon />
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}
export default AddBonus;
