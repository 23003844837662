import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StopIcon from '@mui/icons-material/Stop';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useLocation } from "react-router-dom"; 
const steps = [
  "Initial Details",
  "KYC details",
  "KYC documents",
  "Verification",
  "Configuration",
];
const userId = localStorage.getItem("userId");

const URL = `https://api.aspolite.com/api/employee/getuser?UserId=${userId}`;
const URL1 = `https://api.aspolite.com/api/employee/getuser`;
function EmpVerification() {
  const location = useLocation();
  const  data  = location.state;
  const employeeData = data ? data.employeeData || data.selectedData: null;
  const [userData, setUserData] = useState({});
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  async function handleAccepted(e) {
    e.preventDefault();
    if (employeeData){
      try {
        const res = await axios.post(
          "https://api.aspolite.com/api/employee/verification",
          {
            userId: userId ? userId : employeeData.userId,
            status: 2,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        navigate("/empConfiguration", { state:{ employeeData } })
      } catch (error) {
        console.error(error);
        alert("An error occurred while logging in");
      }
  
    }else{
    try {
      const res = await axios.post(
        "https://api.aspolite.com/api/employee/verification",
        {
          userId: userId,
          status: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      
      navigate("/empConfiguration");
    } catch (error) {
      console.error(error);
      alert("An error occurred while logging in");
    }}
  }
  async function handleRejected(e) {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://api.aspolite.com/api/employee/verification",
        {
          userId: userId,
          status: 3,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      
      navigate("/employee");
    } catch (error) {
      console.error(error);
      alert("An error occurred while logging in");
    }
  }
  async function handleHold(e) {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://api.aspolite.com/api/employee/verification",
        {
          userId: userId,
          status: 4,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      
      navigate("/employee");
    } catch (error) {
      console.error(error);
      alert("An error occurred while logging in");
    }
  }

  const fetchHandler = async () => {
    try {
      if(employeeData){
        const res = await axios.get(`${URL1}?userId=${employeeData.userId} `, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUserData(res.data);
      }else{
        const res = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUserData(res.data);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);

  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmainemp" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h3>Employee Verification</h3>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={3} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div>
            {userData && userData[0] ? (
              <>
                <h5>
                  Initial Details <hr />
                </h5>
                <table style={{ width: "100%", textAlign: "left" }}>
                  <tbody>
                    <tr>
                      <td>
                        <div>First Name: {userData[0].firstName}</div>
                      </td>
                      <td>
                        <div>Last Name: {userData[0].lastName}</div>
                      </td>
                      <td>
                        <div>Email-Id: {userData[0].emailId}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>DOB: {userData[0].dateOfBirth}</div>
                      </td>
                      <td>
                        <div> DOJ: {userData[0].dateOfJoining}</div>
                      </td>
                      <td>
                        <div>Gender: {userData[0].gender}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>BaseLocation: {userData[0].location}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h5>
                  Employee KYC Details <hr />
                </h5>
                <table style={{ width: "100%", textAlign: "left" }}>
                  <tbody>
                    <tr>
                      <td>
                        <div>Father Name: {userData[0].fatherName}</div>
                      </td>
                      <td>
                        <div>Mother Name: {userData[0].motherName}</div>
                      </td>
                      <td>
                        <div>
                          Marital Status:
                          {userData[0].married ? "Married" : "Single"}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>Qualification: {userData[0].qualification}</div>
                      </td>
                      <td
                        style={
                          userData[0].married === true
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div> Spause Name: {userData[0].spouseName}</div>
                      </td>
                      <td>
                        <div>Contact Number: {userData[0].contactNumber}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>Present Address: {userData[0].presentAddress}</div>
                      </td>
                      <td>
                        <div>
                          Permanent Address: {userData[0].permanentAddress}
                        </div>
                      </td>
                      <td>
                        <div>PF: {userData[0].pf}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>UAN: {userData[0].uan}</div>
                      </td>
                      <td>
                        <div>ESIC: {userData[0].esic ? "Yes" : " No"}</div>
                      </td>
                      <td>
                        <div>ESIC Number: {userData[0].esicnumber}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>PAN: {userData[0].pan}</div>
                      </td>
                      <td>
                        <div>
                          Name As Per PanCard:
                          {userData[0].panname}
                        </div>
                      </td>
                      <td>
                        <div>Aadhar Number: {userData[0].aadharNumber}</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          Name As Per Aadhar Card : {userData[0].aadharName}
                        </div>
                      </td>
                      <td>
                        <div>Bank Name: {userData[0].bankName}</div>
                      </td>
                      <td>
                        <div>IFSC Code: {userData[0].bankIfsc}</div>
                      </td>
                    </tr>{" "}
                    <tr>
                      <td>
                        <div>Account Number: {userData[0].bankAccNumber}</div>
                      </td>
                      <td>
                        <div>Branch Name: {userData[0].bankBranch}</div>
                      </td>
                      <td>
                        <div>
                          Police Clearence:
                          {userData[0].pcc ? "Yes" : " No"}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={
                          userData[0].pcc
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div>
                          PCC Validity:
                          {userData[0].pccvalidity}
                        </div>
                      </td>

                      <td   style={
                          userData[0].pcc
                            ? { display: "block" }
                            : { display: "none" }
                        }>
                        <div>PCC Number: {userData[0].pccnumber}</div>
                      </td>

                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div id="btns">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleAccepted}
                    onChange={(e) => handleAccepted(e)}
                  >
                    Accepted
                    <ThumbUpAltIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleRejected}
                  >
                    Rejected
                    <ThumbDownAltIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleHold}
                  >
                    onHold
                    <StopIcon/>
                  </Button>
                </div>
              </>
            ) : (
              "No item found"
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default EmpVerification;
