import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import *as xlsx from "xlsx"

import dayjs from 'dayjs';
const URL = "https://api.aspolite.com/api/attendance/GetAttendanceById";
const URL1 = "https://api.aspolite.com/api/master/location";
const URL2 = "https://api.aspolite.com/api/attendance/DeleteAttendance";
function ViewBiometric() {
  const [options, setOptions] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [startingDate, setStartingDate] = useState("")
  const [endingDate, setEndingDate] = useState("")
  const [days,setDays] = useState("")
  const [exceldata,setExceldata] = useState("")
  const [locationId, setLocationId] = useState("");

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    const token = localStorage.getItem("token");
    const handleChange = (e) => {
        setLocationId(e.target.value);
      };

      const location = useLocation();
      const id = new URLSearchParams(location.search).get('id');
      const locId = new URLSearchParams(location.search).get('location');
      const FromDate = new URLSearchParams(location.search).get('fromDate');
      const toDate = new URLSearchParams(location.search).get('toDate');
    


      const handleDelete = async () =>{
        const token =localStorage.getItem("token")
      
        try {
          const headers = {
            Authorization: `Bearer ${token}`
          };
      
          await axios.post(`${URL2}?id=${id}`, { headers });
          navigate("/biometric");
        } catch (error) {
          console.error("Error deleting data:", error);
        }
      };
    const fetchHandler = async () => {
        // const id = getParameterByName("id")
      try {
        const res = await axios.get(`${URL}?id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRows(res.data);
        setStartingDate(dayjs(FromDate).format('DD-MMM-YYYY') || '');
        setEndingDate(dayjs(toDate).format('DD-MMM-YYYY') || '');
        setLocationId(locId || '');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    const fetchHandler1 = async () => {
        try {
          const res = await axios.get(URL1, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          setOptions(res.data);
        //   setOptions(res.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    useEffect(() => {
  
        fetchHandler();
      fetchHandler1()
    }, []);     

    const handleBack = () => {
      navigate("/biometric")
    }
    const columns = [
        { id: "code", label: "Employee Code", align: "center" },
        { id: "Name", label: "Employee Name", align: "center" },
        {
          id: "location",
          label: "Days",
          minWidth: 50,
          align: "center",
          format: (value) => value.toLocaleString("en-US"),
        },
        {
          id: "present",
          label: "Present",
          minWidth: 50,
          align: "center",
          format: (value) => value.toLocaleString("en-US"),
        },
        {
          id: "absent",
          label: "Absent",
          align: "center",
          format: (value) => value.toFixed(2),
        },
        {
          id: "leaves",
          label: "Leaves",
          align: "center",
          format: (value) => value.toFixed(2),
        },
        {
          id: "earned",
          label: "Earned ",
          minWidth: 100,
          align: "center",
          format: (value) => value.toFixed(2),
        },
        {
            id: "overtime",
            label: "OT ",
            minWidth: 100,
            align: "center",
            format: (value) => value.toFixed(2),
          },
      ];
    return (
      <div>
        <Navbar />
  
        <Box sx={{ display: "flex" }}>
          <Sidebar />
          <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
            
            <div>
              <h1>BioMetric Detail</h1>
            </div>
            <div id="crnbody">
              <div id="row2">
                <div id="firstn">
                  <label>Location</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    displayEmpty
                    className="selLocation"
                    value={locationId}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled>
                      <em>Select Location</em>
                    </MenuItem>
                    {options.map((option) => (
      <MenuItem key={option.id} value={option.name}>
          {option.name}
      </MenuItem>
  ))}
  
                  </Select>
                </div>
                <div id="empbox">
                <label>Starting Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      value={dayjs(startingDate)} 
                      // onChange={(e) => {
                      //   setStartingDate(e);
                      // }}
                      // value={startingDate}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="empbox">
                <label>Ending Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} id="datepic">
                  <DemoContainer
                    components={["DatePicker"]}
                    size="small"
                    fullWidth
                    id="datepic"
                  >
                    <DatePicker
                      size="small"
                      value={dayjs(endingDate)} 
                      // onChange={(e) => {
                      //   setEndingDate(e);
                      // }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              </div>
            
            <Paper sx={{ width: "100%", overflow: "hidden" }} >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
  <TableRow>
    {columns.map((column) => (
      <TableCell
        key={column.id}
        align={column.align}
        style={{
          minWidth: column.minWidth,
          backgroundColor: '#1A447F', 
          color: 'white', 
          fontWeight: 'bold', 
        }}
      >
        {column.label}
      </TableCell>
    ))}
  </TableRow>
</TableHead>
                <TableBody>
                  {rows && rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        console.log(row);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            <TableCell align="center">{row.empCode}</TableCell>
                            <TableCell align="center">{row.empName}</TableCell>
                            <TableCell align="center">{row.days}</TableCell>
                            <TableCell align="center">
                              {row.present}
                            </TableCell>
                            <TableCell align="center">{row.holiday}</TableCell>
                            <TableCell align="center">{row.earnedLeaves}</TableCell>
                            <TableCell align="center">
                            {row.balEarned}
                            </TableCell>
                            <TableCell align="center">
                            {row.balOt}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {rows && rows.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
            </div>
            <Box style={{display:"flex", position: "fixed", bottom: "16px", right: "16px",justifyContent:"space-between" }}  >
          <Button variant="contained" className="subbtn" onClick={handleBack}>
            <ArrowBackIcon />
            Back
          </Button>
          <Button variant="contained" className="danger" style={{marginLeft:"10px"}}  color="error" onClick={handleDelete} >
            <DeleteIcon />
            Delete
          </Button>
        </Box>

          </Box>
        </Box>
      </div>
    );
  
}

export default ViewBiometric