import React from "react";
import Login from "./components/login";
import Forgot from "./components/forgot";
import Verify from "./components/verify";
import Dashboard from "./screens/Dashboard";
import { Routes, Route,Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Employee from "./screens/Employee";
import Attendence from "./screens/Attendence";
import Payroll from "./screens/Payroll";
import Bonus from "./screens/Bonus";
import Advance from "./screens/Advance";
import Reports from "./screens/Reports";
import CreateNew from "./screens/createNew";
import EmployeeRegistration from "./screens/EmployeeRegistration";
import Kyc from "./screens/kyc";
import Kyc_docs from "./screens/kyc_docs";
import EmpVerification from "./screens/EmpVerification";
import Shift from "./screens/Shift"
import EmpConfiguration from "./screens/EmpConfiguration";
import BioMetric from "./screens/biometric";
import UploadNew from "./screens/uploadNew";
import ViewBiometric from "./screens/viewBiometric";
import ViewPayroll from "./screens/viewPayroll"
import Location from "./screens/location";
import CreateNewLocation from "./screens/createNewLocation";
import Wage from "./screens/wage";
import Designation from "./screens/designation";
import CreateNewWage from "./screens/createNewWage";
import CreateNewDesignation from "./screens/createNewDesignation";
import Reguralization from "./screens/reguralization";
import ExitEployee from "./screens/ExitEployee";
import AddBonus from "./screens/AddBonus";
import PfReport from "./screens/pfReport";
import EsicReport from "./screens/esicReport";
import LiabilityReport from "./screens/LiabilityReport";
import AddAdvance from "./screens/AddAdvance";
function App() {
  const isAuthenticated = localStorage.getItem("token");
  return (
    <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/forgot" element={<Forgot />} />
    <Route path="/verify" element={<Verify />} />
    {isAuthenticated ? (
      <>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/shift" element={<Shift />} />
        <Route path="/employee" element={<Employee />} />
        <Route path="/attendence" element={<Attendence />} />
        <Route path="/payroll" element={<Payroll />} />
        <Route path="/bonus" element={<Bonus />} />
        <Route path="/advance" element={<Advance />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/createNew" element={<CreateNew />} />
        <Route path="/empRegistration" element={<EmployeeRegistration />} />
        <Route path="/kyc" element={<Kyc />} />
        <Route path="/kycdocs" element={<Kyc_docs />} />
        <Route path="/empVerification" element={<EmpVerification />} />
        <Route path="/empConfiguration" element={<EmpConfiguration />} />
        <Route path="/biometric" element={<BioMetric />} />
        <Route path="/uploadNew" element={<UploadNew />} />
        <Route path="/viewBiometric" element={<ViewBiometric />} />
        <Route path="/viewPayroll" element={<ViewPayroll />} />
        <Route path="/location" element={<Location />} />
        <Route path="/wage" element={<Wage />} />
        <Route path="/designation" element={<Designation />} />
        <Route path="/createNewLocation" element={<CreateNewLocation />} />
        <Route path="/createNewWage" element={<CreateNewWage />} />
        <Route path="/createNewDesignation" element={<CreateNewDesignation />} />
        <Route path="/regularization" element={<Reguralization />} />
        <Route path="/exitEmployee" element={<ExitEployee />} />
        <Route path="/addBonus" element={<AddBonus />} />
        <Route path="/addAdvance" element={<AddAdvance />} />
        <Route path="/pfReport" element={<PfReport />} />
      
        <Route path="/esicReport" element={<EsicReport />} />
        <Route path="/liabilityReport" element={<LiabilityReport />} />
        
      </>
     ) : (
      <Route path="*" element={<Navigate to="/" />} />
    )} 
  </Routes>
  );
};


export default App;
