import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useState,useEffect } from "react";
function Dashboard() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      {loading ? (
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop> 
      ) : (
        <>
          <Navbar />
          <Sidebar />
        </>
      )}
    </>
  );
}

export default Dashboard;
