import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ListItemText from "@mui/material/ListItemText";
import BadgeIcon from "@mui/icons-material/Badge";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import PaymentIcon from "@mui/icons-material/Payment";
import AddCardIcon from "@mui/icons-material/AddCard";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
                import FingerprintIcon from '@mui/icons-material/Fingerprint';
                import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
                import EngineeringIcon from '@mui/icons-material/Engineering';
                import AssessmentIcon from '@mui/icons-material/Assessment';
import { useNavigate } from "react-router-dom";
import "./login.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [collape, setCollapse] = React.useState(false);
  const [collape1, setCollapse1] = React.useState(false);
  const [collape2, setCollapse2] = React.useState(false);
  const navigate = useNavigate();

  const handleCollape = () => {
  
    setCollapse(!collape);
  };
   const handleCollape1 = () => {
    setCollapse1(!collape1);
  };const handleCollape2 = () => {
    setCollapse2(!collape2);
  };

  const admin = localStorage.getItem("name");
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader id="sidebar">
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountCircle />
              </ListItemIcon>
              <ListItemText
                primary={admin ? admin : "Name"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <IconButton onClick={() => setOpen(!open)}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleCollape}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <TrackChangesIcon />
              </ListItemIcon>
              <ListItemText primary="Master" sx={{ opacity: open ? 1 : 0 }} />
          {/* <ArrowDropUpIcon  /> */}

            </ListItemButton>
          </ListItem>
          <Collapse in={collape} onClick={(e) => e.stopPropagation()}>
            <ListItem
            style={{marginLeft:"20px"}}
              disablePadding
              sx={{ display: "block" }}
         
            >
              <ListItemButton
                   onClick={(event) => {
                    event.stopPropagation();
                    navigate("/location");
                  }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Location"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              style={{marginLeft:"20px"}}
              sx={{ display: "block" }}
              onClick={(event) => {
                event.stopPropagation();
                navigate("/shift");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FilterTiltShiftIcon />
                </ListItemIcon>
                <ListItemText primary="Shift" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              style={{marginLeft:"20px"}}
              sx={{ display: "block" }}
              onClick={(event) => {
                event.stopPropagation();
                navigate("/wage");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ViewAgendaIcon />
                </ListItemIcon>
                <ListItemText primary="Wage" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              style={{marginLeft:"20px"}}
              sx={{ display: "block" }}
              onClick={(event) => {
                event.stopPropagation();
                navigate("/designation");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <EngineeringIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Designation"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Collapse>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/employee");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText primary="Employee" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleCollape1}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <CoPresentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Attendence"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Collapse in={collape1}>
            <ListItem
            style={{marginLeft:"20px"}}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/biometric");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <FingerprintIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BadgeIcon />
                </FingerprintIcon>
                <ListItemText
                  primary="BioMetric"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              style={{marginLeft:"20px"}}
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/regularization");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BadgeIcon />
                </ListItemIcon>
                <ListItemText primary="Reguralization" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
           
            
          </Collapse>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/payroll");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText primary="Payroll" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/bonus");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AddCardIcon />
              </ListItemIcon>
              <ListItemText primary="Bonus" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/advance");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <CardMembershipIcon />
              </ListItemIcon>
              <ListItemText primary="Advance" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={handleCollape2}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <Collapse in={collape2}>
            <ListItem
            style={{marginLeft:"20px"}}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/pfReport");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <AssessmentIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BadgeIcon />
                </AssessmentIcon>
                <ListItemText
                  primary="PF Reports"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              style={{marginLeft:"20px"}}
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/esicReport");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <SummarizeIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BadgeIcon />
                </SummarizeIcon>
                <ListItemText primary="ESIC Reports" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem> <ListItem
              disablePadding
              style={{marginLeft:"20px"}}
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/liabilityReport");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <BadgeIcon />
                </ListItemIcon>
                <ListItemText primary="Liability Report" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
           
            
          </Collapse>
        </List>
        <Divider />
        <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              localStorage.clear()
              navigate("/");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
      </Drawer>
    </Box>
  );
}
