import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LiabilityChart = ({ data, onBarClick }) => {
  const locations = data.map((item) => item.locationName);
  const liabilities = data.map((item) => item.liability);

  const totalLocations = locations.length;
  const scaledLiabilities = liabilities.map(
    (liability) => liability / totalLocations
  );

  const chartData = {
    labels: locations,
    datasets: [
      {
        label: "Liability",
        data: scaledLiabilities,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    onClick: (e, element) => {
      if (element.length > 0) {
        const index = element[0].index;
        onBarClick(data[index]);
      }
    },
  };
  return (
    <div style={{ width: "50%", height: "auto", margin: "0 auto" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default LiabilityChart;
