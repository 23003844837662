import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';

import axios from "axios";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const steps = [
  "Initial Details",
  "KYC details",
  "KYC documents",
  "Verification",
  "Configuration",
];
const URL = "https://api.aspolite.com/api/master/location";

function EmployeeRegistration() {

  const location = useLocation();
  const  data  = location.state;
  const employeeData = data  ? data.selectedData : {};
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [firstName, setFirstName] = useState(employeeData.userId ? employeeData.firstName : "");
  const [lastName, setLastName] = useState(employeeData.userId ? employeeData.lastName : "");
  const [email, setEmail] =  useState(employeeData.userId ? employeeData.emailId : "");
  const [dob, setdob] = useState(
    employeeData.userId ? dayjs(employeeData.dateOfBirth, { format: "MM/DD/YYYY" }) : null
  );
  const [doj, setdoj] = useState(employeeData.userId ? dayjs(employeeData.dateOfJoining, { format: "MM/DD/YYYY" }) : null);
  const [gender, setGender] = useState(employeeData.userId ? employeeData.gender : "");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLocationId(e.target.value);
  };
  const token = localStorage.getItem("token");

  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);
  useEffect(() => {
    if (employeeData.userId && options.length > 0) {
        const selectedOption = options.find(option => employeeData.location === option.name);
        if (selectedOption) {
            setLocationId(selectedOption.id);
        }
    }
}, [employeeData, options]);


  async function handleSubmit(e) {
    e.preventDefault();
if (employeeData.userId){
  navigate("/kyc", { state:{ employeeData } })
}else{
  try {
    const res = await axios.post(
      "https://api.aspolite.com/api/employee/register",
      {
        FirstName: firstName,
        LastName: lastName,
        LocationId: locationId,
        EmailId: email,
        DateOfBirth: dob ? dob.format("DD-MMM-YYYY") : null,
        DateOfJoining: doj ? doj.format("DD-MMM-YYYY") : null,
        Gender: gender,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const userId = res.data.data.userId;
    localStorage.setItem("userId", userId );
    navigate("/kyc");
  } catch (error) {
    console.error(error);
    alert("An error occurred while logging in");
  }
}


  }
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmainemp" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h3>Employee Registration</h3>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div id="empbody">
            <div id="row2">
              <div id="empbox">
                <label>First Name</label>
                <TextField
                  id="outlined-required"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth 
                  value={firstName}
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Last Name</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={lastName}
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>Email ID</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={email}
                  type="email"
                  placeholder="Enter Email Id"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div id="empbox">
                <label>DOB</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      value={dob}       
                      onChange={(e) => {
                        setdob(e);
                      }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="empbox">
                <label>DOJ</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} id="datepic">
                  <DemoContainer
                    components={["DatePicker"]}
                    size="small"
                    fullWidth
                    id="datepic"
                  >
                    <DatePicker
                      size="small"
                      value={doj}
                      onChange={(e) => {
                        setdoj(e);
                      }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>Location</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  className="selLocation"
                  // defaultValue={location}
                  value={locationId}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                  required
                >
                  <MenuItem disabled>
                    <em>Select Location</em>
                  </MenuItem>
                  {options &&
                    options.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>

              <div id="empbox">
                <label>Gender</label>
                <Select
                  // onChange={handleChange}
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  required
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Select Gender</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Button
              variant="contained"
              className="subbtn"
              onClick={handleSubmit}
            >
              Next
              <NavigateNextIcon/>
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default EmployeeRegistration;
