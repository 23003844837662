import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import axios from "axios";
const steps = [
  "Initial Details",
  "KYC details",
  "KYC documents",
  "Verification",
  "Configuration",
];

function Kyc() {
  const location = useLocation();
  const  data  = location.state;
  const employeeData = data?data.employeeData || data.selectedData:[]
  const navigate = useNavigate();
  const [fatherName, setFatherName] = useState(employeeData.userId ? employeeData.fatherName: "");
  const [motherName, setMotherName] = useState(employeeData.userId ? employeeData.motherName: "");
  const [maritalStatus, setMaritalStatus] = useState(employeeData.userId ? employeeData.married: "");
  const [qualification, setQualification] = useState(employeeData.userId ? employeeData.qualification: "");
  const [spauceName, setSpouceName] = useState(employeeData.userId ? employeeData.spouseName: "");
  const [presentAdd, setPresentAdd] = useState(employeeData.userId ? employeeData.presentAddress: "");
  const [permanentAdd, setPermanentAdd] = useState(employeeData.userId ? employeeData.permanentAddress: "");
  const [contactNumber, setContactNumber] = useState(employeeData.userId ? employeeData.contactNumber: "");
  const [pf, setPf] = useState(employeeData.userId ? employeeData.pf: "");
  const [uan, setUan] = useState(employeeData.userId ? employeeData.uan: "");
  const [esic, setEsic] = useState(employeeData.userId ? employeeData.esic: "");
  const [esicno, setEsicNo] = useState(employeeData.userId ? employeeData.esicnumber: "");
  const [pan, setPan] = useState(employeeData.userId ? employeeData.pan: "");
  const [panName, setPanName] = useState(employeeData.userId ? employeeData.panname: "");
  const [aadhar, setAadhar] = useState(employeeData.userId ? employeeData.aadharNumber: "");
  const [aadharName, setAadharName] = useState(employeeData.userId ? employeeData.aadharName: "");
  const [bankName, setBankName] = useState(employeeData.userId ? employeeData.bankName: "");
  const [branchName, setBranchName] = useState(employeeData.userId ? employeeData.bankBranch: "");
  const [ifsCode, setIfscCode] = useState(employeeData.userId ? employeeData.bankIfsc: "");
  const [accNo, setAccNo] =useState(employeeData.userId ? employeeData.bankAccNumber: "");
  const [policeClearence, setPoliceClearence] =useState(employeeData.userId ? employeeData.pcc: "");
  const [pccValidity, setPccVaalidity] = useState(employeeData.userId && employeeData.pccvalidity ? dayjs(employeeData.pccvalidity, { format: "MM/DD/YYYY" }) : null);
  const [pccNumber, setPccNumber] = useState(employeeData.userId ? employeeData.pccnumber: "");
  const URL1 = "https://api.aspolite.com/api/employee/getuser";
  const token = localStorage.getItem("token");
  const userId = parseInt(localStorage.getItem("userId"), 10);

  async function handleSubmit(e) {

    e.preventDefault();
    try {

      if(employeeData.userId){
        const res = await axios.post(
          `https://api.aspolite.com/api/employee/registerKYC?userId=${employeeData.userId}`,
          {
            userId: employeeData.userId,
            fatherName: fatherName,
            motherName: motherName,
            married: maritalStatus,
            qualification: qualification,
            SpouseName: spauceName,
            presentAddress: presentAdd,
            permanentAddress: permanentAdd,
            contactNumber: contactNumber,
            pf: pf,
            uan: uan,
            esic: esic,
            esicNumber: esicno,
            pan: pan,
            panName: panName,
            aadharNumber: aadhar,
            aadharName: aadharName,
            bankName: bankName,
            bankIFSC: ifsCode,
            bankBranch: branchName,
            bankAccNumber: accNo,
            pcc: policeClearence,
            pccValidity: pccValidity ? pccValidity.format("DD-MMM-YYYY") : null,
            pccNumber: pccNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        navigate("/kycdocs", { state:{ employeeData } })
      }else{
        const res = await axios.post(
          "https://api.aspolite.com/api/employee/registerKYC",
          {
            userId: userId,
            fatherName: fatherName,
            motherName: motherName,
            married: maritalStatus,
            qualification: qualification,
            spauseName: spauceName,
            presentAddress: presentAdd,
            permanentAddress: permanentAdd,
            contactNumber: contactNumber,
            pf: pf,
            uan: uan,
            esic: esic,
            esicNumber: esicno,
            pan: pan,
            panName: panName,
            aadharNumber: aadhar,
            aadharName: aadharName,
            bankName: bankName,
            bankIFSC: ifsCode,
            bankBranch: branchName,
            bankAccNumber: accNo,
            pcc: policeClearence,
            pccValidity: pccValidity ? pccValidity.format("DD-MMM-YYYY") : null,
            pccNumber: pccNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        navigate("/kycdocs");
      }
      
    } catch (error) {
      console.error(error);
      alert("An error occurred");
    }
  }

  async function hanldePrevious(e) {
    try {
      const res = await axios.get(`${URL1}?UserId=${employeeData ? employeeData.userId : userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const selectedData = res.data[0]
      navigate("/empRegistration", { state:{ selectedData } })
  
    } catch (error) {
      console.error("Error fetching data:", error);
  
    }
  }
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmainemp" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h3>Employee Registration</h3>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={1} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div id="empbody">
            <div id="row2">
              <div id="empbox">
                <label>Father Name</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  placeholder="Enter Father Name"
                  value={fatherName}
                  onChange={(e) => {
                    setFatherName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Mother Name</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={motherName}
                  placeholder="Enter Mother Name"
                  onChange={(e) => {
                    setMotherName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Marital Status</label>
                <Select
                  // onChange={handleChange}
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={maritalStatus}
                  onChange={(e) => {
                    setMaritalStatus(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Select Marital Status</em>
                  </MenuItem>
                  <MenuItem value={true}>Married</MenuItem>
                  <MenuItem value={false}>Single</MenuItem>
                </Select>
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>Qualification</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={qualification}
                  placeholder="Enter Qualification"
                  onChange={(e) => {
                    setQualification(e.target.value);
                  }}
                />
              </div>
              <div
                id="empbox"
                style={
                  maritalStatus ? { display: "block" } : { display: "none" }
                }
              >
                <label>Spauce Name</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  value={spauceName}
                  fullWidth
                  placeholder="Enter Spauce Name"
                  onChange={(e) => {
                    setSpouceName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Present Address</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={presentAdd}
                  placeholder="Enter Present Address"
                  onChange={(e) => {
                    setPresentAdd(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>Permanent Address</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={permanentAdd}
                  placeholder="Enter Permanent Address"
                  onChange={(e) => {
                    setPermanentAdd(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Contact Number</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={contactNumber}
                  placeholder="Enter Contact Number"
                  onChange={(e) => {
                    setContactNumber(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>PF</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter PF Number"
                  value={pf}
                  onChange={(e) => {
                    setPf(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>UAN</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  placeholder="Enter UAN"
                  value={uan}
                  onChange={(e) => {
                    setUan(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>ESIC</label>
                <Select
                  // onChange={handleChange}
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={esic}
                  onChange={(e) => {
                    setEsic(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Select ESIC</em>
                  </MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </div>
              <div
                id="esicbox"
                style={esic ? { display: "block" } : { display: "none" }}
              >
                <label>ESIC Number</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  placeholder="Enter ESI Number"
                  value={esicno}
                  onChange={(e) => {
                    setEsicNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>PAN</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={pan}
                  placeholder="Enter PAN Number"
                  onChange={(e) => {
                    setPan(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Name As Per PAN</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={panName}
                  placeholder="Enter Name As Per PAN"
                  onChange={(e) => {
                    setPanName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Aadhar Number</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  value={aadhar}
                  fullWidth
                  placeholder="Enter Aadhar Number"
                  onChange={(e) => {
                    setAadhar(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>Name As Per Aadhar</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  value={aadharName}
                  fullWidth
                  placeholder="Enter Name As Per Aadhar"
                  onChange={(e) => {
                    setAadharName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Bank Name</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={bankName}
                  placeholder="Enter Bank Name"
                  onChange={(e) => {
                    setBankName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>IfSC Code</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  value={ifsCode}
                  fullWidth
                  placeholder="Enter IFSC Code"
                  onChange={(e) => {
                    setIfscCode(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="row2">
              <div id="empbox">
                <label>Branch Name</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  value={branchName}
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  placeholder="Enter Branch Name"
                  onChange={(e) => {
                    setBranchName(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Account no</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  value={accNo}
                  fullWidth
                  placeholder="Enter Account No"
                  onChange={(e) => {
                    setAccNo(e.target.value);
                  }}
                />
              </div>
              <div id="empbox">
                <label>Police Clearence</label>
                <Select
                  // onChange={handleChange}
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={policeClearence}
                  onChange={(e) => {
                    setPoliceClearence(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Police Clearence</em>
                  </MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </div>
            </div>

            <div id="row2">
              {/* <div
                id="pccV"
                style={
                  policeClearence ? { display: "block" } : { display: "none" }
                }
              >
                <label>PCC Validity</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  placeholder="Enter PCC Validity"
                  onChange={(e) => {
                    setPccVaalidity(e.target.value);
                  }}
                />
              </div> */}
              <div
                id="pccV"
                style={
                  policeClearence ? { display: "block" } : { display: "none" }
                }
              >
                <label>PCC Validity</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      value={pccValidity}
                      fullWidth
                      onChange={(e) => {
                        setPccVaalidity(e);
                      }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div
                id="pccN"
                style={
                  policeClearence ? { display: "block" } : { display: "none" }
                }
              >
                <label>PCC Number</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={pccNumber}
                  placeholder="Enter PCC Number"
                  onChange={(e) => {
                    setPccNumber(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              style={{ display: "flex", justifyContent: "end", width: "100%" }}
            >
              <Button
                variant="contained"
                className="subbtn"
                color="success"
                onClick={hanldePrevious}
                style={{marginRight:"10px"}}
              >
                <NavigateBeforeIcon />
                Previous
              
              </Button>
              <Button
                variant="contained"
                className="subbtn"
                onClick={handleSubmit}
              >
                Next
                <NavigateNextIcon/>
              </Button>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Kyc;
