import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import DangerousIcon from "@mui/icons-material/Dangerous";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
function AddAdvance() {
  const URL3 = "https://api.aspolite.com/api/advance/statement"
  const URL2 = "https://api.aspolite.com/api/advance/employees";
  const URL1 = "https://api.aspolite.com/api/report/location";
  const URL = "https://api.aspolite.com/api/advance/save";
  const location = useLocation();
  const data = location.state;
  const shiftData = data && data.selectedShift ? data.selectedShift : {};
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [advanceDate, setAdvanceDate] = useState();
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [bonusAmount, setBonusAmount] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [empData,setEmpData] = useState([]);
  const [empNameSel,setEmpNameSel] = useState("");
  const [empDetail,setEmpDetail] = useState();
  const [advAmount,setAdvAmount] = useState();
  const [advAmountData,setAdvAmountData] =useState([]);
  const navigate = useNavigate();
  const columns = [
    { id: "SrNo", label: "Sr No", align: "center" },
    { id: "date", label: "Date", align: "center" },
    { id: "mode", label: "Mode", align: "center" },
    { id: "amount", label: "Amount", minWidth: 50, align: "center" },

  ];

  const fetchHandlerEmp = async () => {
    try {
      const res = await axios.get(URL2, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEmpData(res.data);
      console.log(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandlerEmp();
  }, []);




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChange = (e) => {
    setLocationId(e.target.value);
  }; 
  
  const handleChangeEmp = (e) => {
    console.log(e.target.value);
    setEmpNameSel(e.target.value);

  };
  const token = localStorage.getItem("token");

  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);

  const handleCheckboxToggle = (row) => {
    if (checkedRows.includes(row)) {
      setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    } else {
      setCheckedRows([...checkedRows, row]);
    }
  };

  const handleEdit = (id) => {
    const selectedShift = rows.find((row) => row.id === id);
    const fetchHandler1 = async () => {
      try {
        const res = await axios.get(`${URL1}?UserId=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const selectedData = res.data[0];
        setLoading(false);
        navigate("/empRegistration", { state: { selectedData } });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchHandler1();
  };

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleloadEmployees = async () => {
    try {
      const res = await axios.post(
        URL2,
        {
          locationId: 1,
          fromDate: fromDate,
          toDate: toDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRows(res.data);
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  };

  useEffect(() => {
    if (shiftData.id && options.length > 0) {
      const selectedOption = options.find(
        (option) => shiftData.location === option.name
      );
      if (selectedOption) {
        setLocationId(selectedOption.id);
      }
    }
  }, [shiftData, options]);
  const handleBack = () => {
    navigate("/shift");
  };
  async function handleSubmit(e) {
    e.preventDefault();
    try {

      const res = await axios.post(
        URL,
        {
  date :advanceDate,
  userId : empDetail[0].userId,
  empCode : empDetail[0].empCode,
  empName : empNameSel,
  locationId :locationId,
  location : empDetail[0].location,
  amount : advAmount
  
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/advance");
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  }
  useEffect(() => {
    console.log(empData,empNameSel)
   if(empNameSel !==  undefined || ""){
    console.log(empNameSel)
    const emp = empData.filter((emp) => emp.empName === empNameSel)
    setEmpDetail(emp)
   }
 
  }, [empData, empNameSel])
  useEffect(() => {
    const fetchAdvAmountData = async () => {
      if (empDetail) {
        try {
          const res = await axios.get(`${URL3}?userId=${empDetail[0].userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(res.data);
          setAdvAmountData(res.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchAdvAmountData();
  }, [empDetail]);
  
  
console.log(advAmountData)
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>{shiftData.id ? "Edit Bonus" : "Add Advance"}</h1>
          </div>
          <div id="crnbody">
            <div id="row2">
              <div id="empbox">
                <label>Advance Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      value={advanceDate}
                      onChange={(e) => {
                        setAdvanceDate(e);
                      }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="firstn">
                <label>Employee Name</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={empNameSel}
                  onChange={handleChangeEmp}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Select Employee
                  </MenuItem>
                  {empData.map((option) => (
                    <MenuItem key={option.id} value={option.empName}>
                      {option.empName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div id="firstn">
                <label>Location</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={empDetail && empDetail[0]?empDetail[0].location:""}
                  
                />
              </div>  
            </div>
            <div id="row2">
            <div id="firstn">
                <label>Status</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={empDetail && empDetail[0]?empDetail[0].status:""}
                  
                />
              </div>
               <div id="firstn">
                <label>Employee Code</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={empDetail && empDetail[0]?empDetail[0].empCode:""}
                  
                />
              </div> 
              <div id="firstn">
                <label>Advance Amount</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={advAmount}
                  onChange={(e) => setAdvAmount(e.target.value)}
                  
                />
              </div>
            </div>
          </div>
         
          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            style={
              rows
                ? { display: "block", marginTop: "10px" }
                : { display: "none" }
            }
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {advAmountData && advAmountData.length ? (
                    advAmountData
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        console.log(row)
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index}
                          >
                        
                            <TableCell align="center">{index + 1}</TableCell>{" "}
                            <TableCell align="center">{row.date}</TableCell>
                            <TableCell align="center">{row.mode}</TableCell>
                            <TableCell align="center">{row.amount}</TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {rows && rows.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 6, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              className="subbtn"
              onClick={handleBack}
              style={{
                position: "fixed",
                bottom: "16px",
                right: "140px",
                marginLeft: "10px",
              }}
            >
              <ArrowBackIcon />
              Back
            </Button>
            <Button
              variant="contained"
              className="subbtn"
              color="success"
              onClick={handleSubmit}
              style={{
                position: "fixed",
                bottom: "16px",
                right: "16px",
                marginLeft: "10px",
              }}
            >
              Submit
              <NavigateNextIcon />
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}
export default AddAdvance;
