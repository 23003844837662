import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";

import Button from "@mui/material/Button";

import Textarea from "@mui/joy/Textarea";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
function ExitEployee() {
  return (
    <div>
        <Navbar />
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" id="boxmainemp" sx={{ flexGrow: 1, p: 3 }}>
        <div>
          <h3>Employee Exit Details</h3>
        </div>
        <div id="row1">
        <div id="empbodycon">
              <label>Reason</label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                displayEmpty
                className="selLocation"
                // value={selectedShift}
                // onChange={(e) => {
                //   setSelectedShift(e.target.value);
                // }}
              >
                <MenuItem disabled>
                  <em>Select Reason</em>
                </MenuItem>
                  <MenuItem  value={"resigned"}>Resigned</MenuItem>
                  <MenuItem  value={"terminated"}>Terminated</MenuItem>
                  <MenuItem  value={"absconded"}>Absconded</MenuItem>
              </Select>
            
            </div>
            <div>
                <label>Intimation Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                    //   value={pccValidity}
                      fullWidth
                    //   onChange={(e) => {
                    //     setPccVaalidity(e);
                    //   }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              </div>
              <div>
              <label>Comment</label>
              <Textarea
                placeholder="Type anything…"
                multiline
                // value={shiftComment}
                // onChange={(e) => setShiftComment(e.target.value)}
              />
            </div>
            <div id="row1">
            <div>
                <label>Intimation Acceptence</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                    //   value={pccValidity}
                      fullWidth
                    //   onChange={(e) => {
                    //     setPccVaalidity(e);
                    //   }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div>
                <label>Last Working Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                    //   value={pccValidity}
                      fullWidth
                    //   onChange={(e) => {
                    //     setPccVaalidity(e);
                    //   }}
                      format="DD-MMM-YYYY"
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <label>Remark</label>
              <Textarea
                placeholder="Type anything…"
                multiline
                rows={2}
                // value={shiftComment}
                // onChange={(e) => setShiftComment(e.target.value)}
              />
            </div>

            <div
            style={{ display: "flex", justifyContent: "end", width: "100%", marginTop:"10px"}}
          >
            <Button
              variant="contained"
              className="subbtn"
            //   onClick={handleSubmit}
            >
              Submit
              <NavigateNextIcon/>
            </Button>
          </div>
        </Box>
        </Box>
        </div>
  )
}

export default ExitEployee