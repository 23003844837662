import React from "react";
import { Grid, Paper, TextField, Button, Link } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import "./login.css";
function Verify() {
  return (
    <Grid container className="login-container">
      <div id="imgdiv">
        <img src="./images/logo.png" alt="Logo" className="login-image" />
      </div>
      <Paper elevation={10} className="login-paper-confirm">
        <h2>Change Password</h2>
        <div id="lgn">
          <div id="d1">
            <label>OTP</label>
            <MuiOtpInput size="small" id="otp" />
          </div>
          <div>
            <label>Create New Password</label>
            <TextField
              id="outlined-basic"
              className="txtfield"
              size="small"
              variant="outlined"
              type="email"
              fullWidth
              placeholder="Enter New Password"
            />
          </div>
          <div>
            <label>Confirm Password</label>
            <TextField
              id="outlined-basic"
              className="txtfield"
              size="small"
              variant="outlined"
              type="email"
              fullWidth
              placeholder="Confirm Your Password"
            />
          </div>
        </div>
        <div id="lgnconfirm">
          <Link href="./" underline="none" fullWidth id="link">
            <Button variant="contained" id="lgbtnc" fullWidth>
              Confirm
            </Button>
          </Link>
        </div>
      </Paper>
    </Grid>
  );
}

export default Verify;
