import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
  Grid,
  Paper,
  TextField,
  Button,
  Link,
} from "@mui/material";
import axios from "axios";
import "./login.css";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [vertical] = useState('top');
  const [horizontal] = useState('center');
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setOpenSecond(false);
  };

  async function submit(e) {
    e.preventDefault();

    if (!email || !password) {
      if (!email) {
        document.getElementById("email").innerHTML = "Please Enter Email Address";
      }
      if (!password) {
        document.getElementById("pass").innerHTML = "Please Enter Password";
      }
      setOpenSecond(true);
      return;
    }

    try {
      const res = await axios.post(
        "https://api.aspolite.com/api/account/login",
        {
          email,
          password,
        }
      );
      console.log(res.data)
      const token = res.data.data.bearerToken;
      const name = res.data.data.loginName;
      localStorage.setItem("token", token);
      localStorage.setItem("name", name);
      console.log(res.status)
      if (res.status === 200) {
        navigate("/dashboard");
        console.log("navigate")
      } else {
        setOpenSecond(true);
      }
    } catch (error) {
      console.error(error.response);
      setOpen(true);
    }
  }
  console.log(email,password)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      submit(e);
    }
  };

  return (
    <Grid container className="login-container">
      <div id="imgdiv">
        <img src="./images/logo.png" alt="Logo" className="login-image" />
      </div>
      <Paper elevation={10} className="login-paper">
        <div id="lgn">
          <div>
            <label>Email id</label>
            <br/>
            <span id="email" style={{color:'red',fontSize:"10px"}}></span>
            <TextField
              id="outlined-basic"
              className="txtfield"
              size="small"
              variant="outlined"
              type="email"
              fullWidth
              placeholder="Enter Email Id"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div>
            <label>Password</label> 
            <br/>
            <span id="pass" style={{color:'red',fontSize:"10px"}}> </span> 
            <TextField
              id="outlined-basic"
              className="txtfield"
              size="small"
              variant="outlined"
              type="password"
              fullWidth
              placeholder="Enter Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div className="footer">
          <Link href="./forgot" underline="hover">
            Forgot Password
          </Link>
        </div>
        <div id="lgndiv">
          <Button variant="contained" id="lgbtn" fullWidth onClick={submit}>
            Login
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <MuiAlert onClose={handleClose} severity="error">
            An error occurred while logging in. Please check your credentials.
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSecond}
          onClose={handleClose}
          key={vertical + horizontal}
        >
          <MuiAlert onClose={handleClose} severity="error">
            Please Enter the credentials
          </MuiAlert>
        </Snackbar>
      </Paper>
    </Grid>
  );
}

export default Login;
