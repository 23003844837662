import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as xlsx from "xlsx";

import dayjs from "dayjs";
const URL = "https://api.aspolite.com/api/master/location";
const URL1 = "https://api.aspolite.com/api/attendance/GetUserList";
const URL3 = "https://api.aspolite.com/api/attendance/DownloadTemplate";
function UploadNew() {
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState(0);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isTableOpen, setIsTableOpen] = useState(false);
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [days, setDays] = useState();
  const [sundays, setSundays] = useState();
  const [CalculatedDays, setCalculatedDays] = useState();
  const [presentValue, setPresentValue] = useState(0);
  const [absentValue, setAbsentValue] = useState(0);
  const [exceldata, setExceldata] = useState([]);
  const navigate = useNavigate();
  const [presentValues, setPresentValues] = useState([]);
  const [absentValues, setAbsentValues] = useState([]);
  const [elValues, setElValues] = useState([]);
  const [otValues, setOTValues] = useState([]);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    const initialPresentValues = Array(rows.length).fill(0);
    const initialAbsentValues = Array(rows.length).fill(0);
    setPresentValues(initialPresentValues);
    setAbsentValues(initialAbsentValues);
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const token = localStorage.getItem("token");

  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchHandler1 = async () => {
    try {
      const res = await axios.get(URL1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRows(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
    fetchHandler1();
  }, []);

  async function handleSave(e) {
    const token = localStorage.getItem("token");

    e.preventDefault();
    const userId = parseInt(localStorage.getItem("userId"), 10);

    try {
      const filteredRows = rows.filter((row) => {
        const data = exceldata.filter(
          (element) => element.Code === row.empCode
        );
        console.log(`Data for empCode ${row.empCode}:`, data);
        return data.length > 0;
      });

      console.log("Filtered rows:", filteredRows);
      const res = await axios.post(
        "https://api.aspolite.com/api/attendance/SubmitAttendance",
        {
          LocationId: locationId,
          FromDate: startingDate ? startingDate.format("DD-MMM-YYYY") : null,
          ToDate: endingDate ? endingDate.format("DD-MMM-YYYY") : null,
          TotalEmployees: filteredRows.length,
          TotalRecords: exceldata.length,
          Month: status,
          Details: filteredRows.map((row, index) => {
            const data = exceldata
              ? exceldata.filter((element) => element.Code == row.empCode)
              : null;
            const isDataMatching = data !== null && data.length > 0;
            const adjustedDays = row.wageCode === "MWORK" ? days - sundays : days;
            return {
              UserId: isDataMatching ? row.userId : 0,
              EmpCode: isDataMatching ? row.empCode : null,
              EmpName: isDataMatching ? row.fullName : null,
              Days: adjustedDays,
              BalEarned: row.leave,
              BalOt: row.ot,
              Present:
                isDataMatching && data[0]
                  ? parseInt(presentValues[index] || data[0].Present || 0, 10)
                  : 0,
              Absent:
                isDataMatching && data[0]
                  ? parseInt(absentValues[index] || data[0].Absent || 0, 10)
                  : 0,
            };
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert(res.data.message);
      if (res.data.status === "200") {
        navigate("/biometric");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while logging in");
    }
  }

  const readExcel = async (e) => {
    console.log("Files:", e.target.files);
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    console.log("ArrayBuffer:", data);

    const excelfile = xlsx.read(data, { type: "array" });
    console.log("Excel File:", excelfile);

    const sheetName = excelfile.SheetNames[0];
    console.log("Sheet Name:", sheetName);

    const excelSheet = excelfile.Sheets[sheetName];
    console.log("Excel Sheet:", excelSheet);

    const exceljson = xlsx.utils.sheet_to_json(excelSheet, { defval: null });
    console.log("Excel JSON:", exceljson);

    setExceldata(exceljson);
  };

  const handleDowloadtemplete = async () => {
    try {
      const res = await axios.get(`${URL3}?locationId=${locationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `template.xls`);
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = () => {
    const daysDifference = dayjs(endingDate).diff(dayjs(startingDate), "day") + 1;
    
    let sundaysCount = 0;
    let currentDay = dayjs(startingDate);
  
    while (currentDay.isBefore(dayjs(endingDate)) || currentDay.isSame(dayjs(endingDate), "day")) {
      if (currentDay.day() === 0) { 
        sundaysCount += 1;
      }
      currentDay = currentDay.add(1, "day");
    }
  
    setDays(daysDifference);
    setSundays(sundaysCount); 
    setIsTableOpen(true);
  };
  const columns = [
    { id: "code", label: "Employee Code", align: "center" },
    { id: "Name", label: "Employee Name", align: "center" },
    {
      id: "location",
      label: "Days",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "present",
      label: "Present",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "absent",
      label: "Absent",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "EL",
      label: "EL",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "OT",
      label: "OT Hour",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "designation",
      label: "Designation",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "status",
      label: "Status",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "earned",
      label: "Earned ",
      minWidth: 100,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "overtime",
      label: "OT ",
      minWidth: 100,
      align: "center",
      format: (value) => value.toFixed(2),
    },
  ];

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    setStatus(selectedMonth);
    const year = new Date().getFullYear();
    const startOfMonth = dayjs(`${year}-${selectedMonth}-01`).startOf("month");
    const endOfMonth = dayjs(startOfMonth).endOf("month");
    setStartingDate(startOfMonth);
    setEndingDate(endOfMonth);
  };

  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>BioMetric Detail</h1>
          </div>
          <div id="crnbody">
            <div id="row2">
              <div id="firstn">
                <label>Location</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={locationId}
                  onChange={(e) => setLocationId(e.target.value)}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="0">
                    <em>Select Location</em>
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div id="firstn">
                <label>Month</label>
                <Select
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={status}
                  onChange={handleMonthChange}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="0">
                    <em>Select Month</em>
                  </MenuItem>
                  <MenuItem value={"January"}>January</MenuItem>
                  <MenuItem value={"February"}>February</MenuItem>
                  <MenuItem value={"March"}>March</MenuItem>
                  <MenuItem value={"April"}>April</MenuItem>
                  <MenuItem value={"May"}>May</MenuItem>
                  <MenuItem value={"June"}>June</MenuItem>
                  <MenuItem value={"July"}>July</MenuItem>
                  <MenuItem value={"August"}>August</MenuItem>
                  <MenuItem value={"September"}>September</MenuItem>
                  <MenuItem value={"October"}>October</MenuItem>
                  <MenuItem value={"November"}>November</MenuItem>
                  <MenuItem value={"December"}>December</MenuItem>
                </Select>
              </div>
              <div id="empbox">
                <label>Starting Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    fullWidth
                    value={startingDate}
                    onChange={(e) => setStartingDate(dayjs(e))}
                    required
                  />
                </LocalizationProvider>
              </div>
              <div id="empbox">
                <label>Ending Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    size="small"
                    fullWidth
                    value={endingDate}
                    onChange={(e) => setEndingDate(dayjs(e))}
                    required
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div id="row2">
              <TextField
                type="file"
                style={{ backgroundColor: "white", width: "100% !important" }}
                onChange={(e) => readExcel(e)}
              />

              <div id="cretebtn">
                <Button
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={handleDowloadtemplete}
                >
                  Download Templete
                </Button>
                <Button
                  variant="contained"
                  disabled={exceldata.length === 0}
                  onClick={handleSubmit}
                >
                  Process Biometric
                </Button>
              </div>
            </div>
            <Paper
              sx={{ width: "100%", overflow: "hidden" }}
              style={isTableOpen ? { display: "block" } : { display: "none" }}
            >
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.length > 0 ? (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const data = exceldata
                            ? exceldata.filter(
                                (element) => element.Code === row.empCode
                              )
                            : null;
                          console.log(data);
                          const isDataMatching =
                            data !== null && data.length > 0;
                          console.log(data);
                          const backgroundColor =
                            isDataMatching === false && row.status === "Pending"
                              ? "red"
                              : isDataMatching && row.status === "Pending"
                              ? "yellow"
                              : isDataMatching && row.status === "Accepted"
                              ? "white"
                              : "red";
                          
                              const adjustedDays = row.wageCode === "MWORK" ? days - sundays : days;
                              // setCalculatedDays(adjustedDays)
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              style={{ backgroundColor: backgroundColor }}
                            >
                              <TableCell align="center">
                                {row.empCode}
                              </TableCell>
                              <TableCell align="center">
                                {row.fullName}
                              </TableCell>
                              <TableCell align="center">{adjustedDays}</TableCell>
                              <TableCell align="center">
                                <TextField
                                  type="number"
                                  value={
                                    isDataMatching && data[0]
                                      ? data[0].Present
                                      : presentValues[index]
                                  }
                                  onChange={(e) => {
                                    const newValues = [...presentValues];
                                    newValues[index] = e.target.value;
                                    setPresentValues(newValues);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <TextField
                                  type="number"
                                  value={
                                    isDataMatching && data[0]
                                      ? data[0].Absent
                                      : absentValues[index]
                                  }
                                  onChange={(e) => {
                                    const newValues = [...absentValues];
                                    newValues[index] = e.target.value;
                                    setAbsentValues(newValues);
                                  }}
                                />
                              </TableCell>{" "}
                              <TableCell align="center">
                                <TextField
                                  type="number"
                                  value={
                                    isDataMatching && data[0]
                                      ? data[0].EL
                                      : elValues[index] || 0
                                  }
                                  onChange={(e) => {
                                    const newValues = [...elValues];
                                    newValues[index] = e.target.value;
                                    setElValues(elValues);
                                  }}
                                />
                              </TableCell>{" "}
                              <TableCell align="center">
                                <TextField
                                  type="number"
                                  value={
                                    isDataMatching && data[0]
                                      ? data[0].OTHours
                                      : otValues[index] || 0
                                  }
                                  onChange={(e) => {
                                    const newValues = [...otValues];
                                    newValues[index] = e.target.value;
                                    setOTValues(newValues);
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {row.designation}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={
                                  row.status === "Accepted"
                                    ? { color: "green", fontWeight: "bold" }
                                    : { color: "yellow", fontWeight: "bold" }
                                }
                              >
                                {row.status}
                              </TableCell>
                              <TableCell align="center">{row.leave}</TableCell>
                              <TableCell align="center">{row.ot}</TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {rows && rows.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </div>
          <Button
            variant="contained"
            className="subbtn"
            style={{ position: "fixed", bottom: "16px", right: "16px" }}
            onClick={handleSave}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default UploadNew;
