import React from "react";
import { Grid, Paper, TextField, Button, Link } from "@mui/material";
import { useState } from "react";
import "./login.css";
function Forgot() {
  const [email, setEmail] = useState();

  return (
    <Grid container className="login-container">
      <div id="imgdiv">
        <img src="./images/logo.png" alt="Logo" className="login-image" />
      </div>
      <Paper elevation={10} className="login-paper">
        <h2>Forgot Password</h2>
        <div id="lgn">
          <div>
            <label>Email id</label>
            <TextField
              id="outlined-basic"
              className="txtfield"
              size="small"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
              type="email"
              fullWidth
              placeholder="Enter Email Id"
            />
          </div>
        </div>

        <div id="lgnforgot" style={{ marginBottom: "30px !important" }}>
          <Link href="./" underline="none" fullWidth id="link">
            <Button variant="contained" id="lgbtn1">
              Cancel
            </Button>
          </Link>
          <Link href="./verify" underline="none" fullWidth id="link">
            <Button variant="contained" id="lgbtn2">
              Submit
            </Button>
          </Link>
        </div>
      </Paper>
    </Grid>
  );
}

export default Forgot;
