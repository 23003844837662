import React from "react";
import Sidebar from "../components/sidebar";
import Box from "@mui/material/Box";
import Navbar from "../components/navbar";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import "../screens/screens.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function Advance() {
  const URL =  "https://api.aspolite.com/api/advance/list"
  const URL1 = "https://api.aspolite.com/api/advance/delete"

  const columns = [
    { id: "SRNO", label: "Srno", align: "center" },
    { id: "adDate", label: "Advance Date", align: "center" },
    { id: "empCode", label: "Emp Code", align: "center" },
    
    {
      id: "empName",
      label: "Emp Name",
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "Location",
      label: "Location",
      minWidth: 100,
      align: "center",
      format: (value) => value.toFixed(2),
    },  {
      id: "advAmount",
      label: "Advance Amount",
      minWidth: 100,
      align: "center",
      format: (value) => value.toFixed(2),
    },
  ];
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const token = localStorage.getItem("token");
  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRows(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);
  const handleEdit = (id) => {
    const selectedShift = rows.find((row) => row.id === id);
  
   
    if (selectedShift && Object.keys(selectedShift).length > 0) {
      navigate("/createNewLocation", { state:{ selectedShift } });
    } else { 
      console.error("Selected shift is empty or undefined.");
    }
  };

  const handleDelete = async (id) => {
const token =localStorage.getItem("token")

    try {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const data = { id: id };
      await axios.delete(`${URL1}?id=${id}`, { headers });
      fetchHandler()
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div>
    <Navbar />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>Advance</h1>
          </div>
          {loading ? (
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop> 
          ) :
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
<TableRow>
  {columns.map((column) => (
    <TableCell
      key={column.id}
      align={column.align}
      style={{
        minWidth: column.minWidth,
        backgroundColor: '#1A447F', 
        color: 'white', 
        fontWeight: 'bold', 
      }}
    >
      {column.label}
    </TableCell>
  ))}
</TableRow>
</TableHead>
              <TableBody>
                {rows && rows.length > 0 ? (
                  rows
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row ,index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {/* {columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>
                              {row[column.id]}
                            </TableCell>
                          ))} */}
                          <TableCell align="center">
                            {index +1}
                          </TableCell>   <TableCell align="center">
                            {row.date}
                          </TableCell>
                          <TableCell align="center">{row.empCode}</TableCell>
                          <TableCell align="center">{row.empName}</TableCell>
                          <TableCell align="center">{row.location}</TableCell>
                          {/* <TableCell align="center">{row.amount}</TableCell> */}
                          <TableCell align="center">
                           
                            <Button
                              variant="text"
                              onClick={() => handleDelete(row.id)}
                            >
                              <img
                                src="./images/icons8-delete-30.png"
                                alt="edit"
                                width="25px"
                                height="25px"
                              />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {rows && rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[5,6,25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
}
          <div id="cretebtn">
           
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              style={{ position: "fixed", bottom: "16px", right: "16px" }}
              onClick={() => {
                navigate("/addAdvance");
              }}
            >
              Add
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Advance;
