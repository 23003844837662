import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function CreateNewWage() {
  const location = useLocation();
  const  data  = location.state;
  const shiftData = data && data.selectedShift ? data.selectedShift : {};
  console.log(shiftData)
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [name, setName] = useState(shiftData.id ? shiftData.name : "");
  const [code, setCode] = useState(shiftData.id ? shiftData.code : "");
  const [description, setDescription] = useState(shiftData.id ? shiftData.description : "");
  const [basicPM, setBasicPM] = useState(shiftData.id ? shiftData.basicPerMonth : 0);
  const [basicPD, setBasicPD] = useState(shiftData.id ? shiftData.basicPerDay : 0);
  const [AllowancePM, setAllowancePM] = useState(shiftData.id ? shiftData.allowancePerMonth : 0);
  const [pf, setPf] = useState(shiftData.id ? shiftData.pf : 0);
  const [esic, setEsic] = useState(shiftData.id ? shiftData.esic : 0);
  const [red, setRed] = useState(shiftData.id ? shiftData.red : 0);
  const [rot, setRot] = useState(shiftData.id ? shiftData.rot : 0);
  const [hra, setHra] = useState(shiftData.id ? shiftData.hra : 0);
  const [vda, setVda] = useState(shiftData.id ? shiftData.vda : 0);
  const [otRate, setOtRate] = useState(shiftData.id ? shiftData.otRatePerHour : 0);
  const [status, setStatus] = useState(shiftData.id ? (shiftData.status === "Active" ? 1 : 0) : "");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLocationId(e.target.value);
  };
  const token = localStorage.getItem("token");


  useEffect(() => {
    if (shiftData.id && options.length > 0) {
        const selectedOption = options.find(option => shiftData.location === option.name);
        if (selectedOption) {
            setLocationId(selectedOption.id);
        }
    }
}, [shiftData, options]);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      
      if (shiftData.id) {
        const res = await axios.patch(
          `https://api.aspolite.com/api/master/wage/`,
          {
            id:shiftData.id,
            name,
            code,
            status,
            pf,
            esic,
            hra,
            red,
            rot,
            vda,
            description,
            basicPerMonth:basicPM,
            BasicPerDay:basicPD,
            otRatePerHour:otRate,
            allowancePerMonth:AllowancePM,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,                            
            },
          }
        );
       
        navigate("/wage");
      } else {
        const res = await axios.post(
          "https://api.aspolite.com/api/master/wage",
          {
            name,
            code,
            status,
            pf,
            esic,
            hra,
            red,
            rot,
            vda,
            description,
            basicPerMonth:basicPM,
            BasicPerDay:basicPD,
            otRatePerHour:otRate,
            allowancePerMonth:AllowancePM,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
       ;
        navigate("/wage");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new location");
    }
  }

const handleBack = () => {
    navigate("/wage")
  }
  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>{shiftData.id ? "Edit Wage" : "Create Wage"}</h1>
          </div>
          <div id="crnbody">
            <div id="firstn">
              <label>Name</label>
              <TextField
                id="outlined-basic"
                className="txtfield"
                size="small"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>  
            <div id="firstn">
              <label>Description</label>
              <TextField
                id="outlined-basic"
                className="txtfield"
                size="small"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter Name"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
            <div id="row2">
              <div id="firstn">
                <label>Code</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="text"
                  placeholder="Enter Code"
                  fullWidth
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </div>    <div id="firstn">
                <label>Basic Per Month</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter Basic Per Month"
                  fullWidth
                  value={basicPM}
                  onChange={(e) => {
                    setBasicPM(e.target.value);
                  }}
                />
              </div>    <div id="firstn">
                <label>Basic Per Day</label>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter Basic Per Day"
                  fullWidth
                  value={basicPD}
                  onChange={(e) => {
                    setBasicPD(e.target.value);
                  }}
                />
              </div><div id="firstn">
                <label>Allowanace Per Month</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter Allowance Per Month"
                  fullWidth
                  value={AllowancePM}
                  onChange={(e) => {
                    setAllowancePM(e.target.value);
                  }}
                />
              </div>
 
            </div>  <div id="row2">
            <div id="firstn">
                    <label>PF (%)
                  <span style={{backgroundColor:"#c9f7ff" ,padding:"0px 5px"}}> {(pf * basicPD ) / 100}</span>  
                    </label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      fullWidth
                      variant="outlined"
                      type="number"
                      value={pf}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value <= 100) {
                          setPf(value);
                        }
                      }}
                      inputProps={{ min: 0, max: 100 }}
                    />
                  </div>

                  <div id="firstn">
                    <label>ESIC (%)
                      <span style={{backgroundColor:"#c9f7ff" ,padding:"0px 5px"}}>{(esic * basicPD ) / 100}</span>
                      
                      </label>
                    <TextField
                      id="outlined-basic"
                      className="txtfield"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={
                         esic 
                      }
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value <= 100) {
                          setEsic(value);
                          
                        }
                      }}
                      inputProps={{ min: 0, max: 100 }}
                    />
                  </div>
              
               <div id="firstn">
                <label>Retention</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter Retention"
                  fullWidth
                  value={red}
                  onChange={(e) => {
                    setRed(e.target.value);
                  }}
                />
              </div><div id="firstn">
                <label>Retention OT</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter Retention Ot"
                  fullWidth
                  value={rot}
                  onChange={(e) => {
                    setRot(e.target.value);
                  }}
                />
              </div>
 
            </div>
            </div>  <div id="row2">
              <div id="firstn">
                <label>hra</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter HRA"
                  fullWidth
                  value={hra}
                  onChange={(e) => {
                    setHra(e.target.value);
                  }}
                />
              </div>    <div id="firstn">
                <label>Vda</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter Vda"
                  fullWidth
                  value={vda}
                  onChange={(e) => {
                    setVda(e.target.value);
                  }}
                />
              </div>    <div id="firstn">
                <label>OT Rate Per Hour</label>
                <TextField
                  id="outlined-basic"
                  className="txtfield"
                  size="small"
                  variant="outlined"
                  type="number"
                  placeholder="Enter OT Rate Per Hour"
                  fullWidth
                  value={otRate}
                  onChange={(e) => {
                    setOtRate(e.target.value);
                  }}
                />
              </div> <div id="statusn">
                <label>Status</label>
                <Select
                  // onChange={handleChange}
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>
                    <em>Select Status</em>
                  </MenuItem>
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Pending</MenuItem>
                </Select>
              </div>
 
            </div>

           
          <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
          <Button variant="contained" className="subbtn" onClick={handleBack} 
            style={{ position: "fixed", bottom: "16px", right: "140px", marginLeft:"10px" }}
            >
            <ArrowBackIcon />
            Back
          </Button>
          <Button
            variant="contained"
            className="subbtn"
            color="success"
            onClick={handleSubmit}
            style={{ position: "fixed", bottom: "16px", right: "16px", marginLeft:"10px" }}
          >
            Submit
            <NavigateNextIcon/>
          </Button>
          </div>
        
        </Box>
      </Box>
    </div>
  );
}

export default CreateNewWage;
