import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import DangerousIcon from "@mui/icons-material/Dangerous";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DownloadIcon from "@mui/icons-material/Download";
function PfReport() {
  const URL2 = "https://api.aspolite.com/api/report/pf";
  const URL1 = "https://api.aspolite.com/api/master/location";
  const URL = "http://localhost:3002/api/bonus/save";
  const location = useLocation();
  const data = location.state;

  const shiftData = data && data.selectedShift ? data.selectedShift : {};

  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState(0);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [bonusDate, setBounusDate] = useState();

  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [bonusAmount, setBonusAmount] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);

  const navigate = useNavigate();
  const columns = [
    { id: "index", label: "Sr No", align: "center" },
    { id: "location", label: "Location", align: "center" },
    { id: "month", label: "Month", align: "center" },
    { id: "year", label: "Year", align: "center" },
    { id: "empCode", label: "Emp Code", align: "center" },
    { id: "empName", label: "Emp Name", align: "center" },
    { id: "status", label: "Status", minWidth: 50, align: "center" },
    {
      id: "deducation",
      label: "PF Deduction",
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDownload = () => {
    if (rows && rows.length > 0) {
      const csvRows = [];
      
      // Get headers
      const headers = columns.map(column => column.label).join(",");
      csvRows.push(headers);
  
      // Get rows data
      rows.forEach((row, index) => {
        const values = columns.map(column => {
          let value;
  
          if (column.id === "index") {
            value = index + 1;
          } else {
            value = row[column.id.trim()] !== undefined ? row[column.id.trim()] : "";
          }
  
          if (column.id === "year") {
            value = year.format("YYYY");
          }
  
          return typeof value === "string" ? `"${value}"` : value;
        }).join(",");
        csvRows.push(values);
      });
      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "pf_report.csv");
      a.click();
    } else {
      alert("No data available to download");
    }
  };
  
  

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChange = (e) => {
    setLocationId(e.target.value);
  };
  const token = localStorage.getItem("token");

  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);

  const handleCheckboxToggle = (row) => {
    if (checkedRows.includes(row)) {
      setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    } else {
      setCheckedRows([...checkedRows, row]);
    }
  };

  const handleEdit = (id) => {
    const selectedShift = rows.find((row) => row.id === id);
    const fetchHandler1 = async () => {
      try {
        const res = await axios.get(`${URL1}?UserId=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const selectedData = res.data[0];
        setLoading(false);
        navigate("/empRegistration", { state: { selectedData } });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchHandler1();
  };

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleGenrateReport = async () => {
    try {
      const res = await axios.post(
        URL2,
        {
          locationId: locationId,
          month: month ? month.format("MMM"): "",
          year: year ? year.format("YYYY") : "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setRows(res.data);
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  };
  useEffect(() => {
    if (shiftData.id && options.length > 0) {
      const selectedOption = options.find(
        (option) => shiftData.location === option.name
      );
      if (selectedOption) {
        setLocationId(selectedOption.id);
      }
    }
  }, [shiftData, options]);
  const handleBack = () => {
    navigate("/shift");
  };


  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>PF Report</h1>
          </div>
          <div id="crnbody">
            <div id="row2">
              <div id="firstn">
                <label>Location</label>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  displayEmpty
                  className="selLocation"
                  value={locationId}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="0">
                    <em>Select Location</em>
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div id="empbox">
                <label>Select Year</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="YYYY"
                      views={["year"]}
                      margin="normal"
                      id="date-picker-inline"
                      value={year}
                      onChange={(e) => {
                        setYear(e);
                      }}
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="empbox">
                <label>Select Month</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="MMM"
                      views={["month"]}
                      margin="normal"
                      id="date-picker-inline"
                      value={month}
                      onChange={(e) => {
                        setMonth(e);
                      }}
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div id="cretebtn">
            <Button variant="contained" onClick={handleGenrateReport}>
              Generate Report
            </Button>
          </div>
          <Paper
            sx={{ width: "100%", overflow: "hidden" }}
            style={
              rows
                ? { display: "block", marginTop: "10px" }
                : { display: "none" }
            }
          >
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#1A447F",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {/* {columns.map((column) => (
                              <TableCell key={column.id} align={column.align}>
                                {row[column.id]}
                              </TableCell>
                            ))} */}
                            {/* <TableCell align="center">
        <input
            type="checkbox"
            checked={checkedRows.includes(row)}
            onChange={() => handleCheckboxToggle(row)}
        />
    </TableCell> */}
                            <TableCell align="center">{index + 1}</TableCell>{" "}
                            <TableCell align="center">{row.location}</TableCell>
                            <TableCell align="center">{row.month}</TableCell>
                            <TableCell align="center">{year.format("YYYY")}</TableCell>
                            <TableCell align="center">{row.empCode}</TableCell>
                            <TableCell align="center">{row.empName}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">{row.deducation}</TableCell>
                            
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {rows && rows.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[5, 6, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
           <Button
                  variant="contained"
                  color="error"
                  onClick={handleDownload}
                  style={{
                    position: "fixed",
                    bottom: "16px",
                    right: "16px",
                    marginLeft: "10px",
                  }}
                >
                  Download
                  <DownloadIcon />
                </Button>
           
          </div>
        </Box>
      </Box>
    </div>
  );
}
export default PfReport;
