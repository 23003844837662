import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const steps = [
  "Initial Details",
  "KYC details",
  "KYC documents",
  "Verification",
  "Configuration",
];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function Kyc_docs() {
  const location = useLocation();
  const data = location.state;
  const employeeData = data ? data.employeeData : "";
  const [uploadedFiles, setUploadedFiles] = useState(employeeData.userId ? employeeData.userDocuments : []);
  const [eduFiles, setEduFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [existFile, setExistFile] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const URL1 = "https://api.aspolite.com/api/employee/getuser";
  const userId = localStorage.getItem("userId");
  const handleDelete = (uploadFiles, index) => {

    const res = axios.post(
      `https://api.aspolite.com/api/employee/DeleteDocument?DocumentId=${uploadFiles[index].documentTypeId}&${uploadFiles[index].document}`,
      {

      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
  };
  const handleDel = (uploadFiles) => {

    const token = localStorage.getItem("token");
    const res = axios.post(
      `https://api.aspolite.com/api/employee/DeleteDocument?DocumentId=${uploadFiles.documentTypeId}&${uploadFiles.document}`,
      {

      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
  };
  const handleDownload = async (document) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `https://api.aspolite.com/api/employee/DownloadDocument?path=${document.document}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/pdf',
          },

        }
      );

      saveAs(new Blob([response.data]), document.documentName);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  async function hanldePrevious(e) {
    try {
      const res = await axios.get(`${URL1}?UserId=${employeeData ? employeeData.userId : userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const selectedData = res.data[0]
      navigate("/kyc", { state:{ selectedData } })
  
    } catch (error) {
      console.error("Error fetching data:", error);
  
    }
  }
  const handleFileChange = (file, documentTitle, docId) => {
    const updatedFiles = [...uploadedFiles];
    const newIndex = uploadedFiles.length;
    updatedFiles[newIndex] = {
      documentTypeId: docId,
      documentName: documentTitle,
      file: file,
    };

    setUploadedFiles(updatedFiles);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const documents = new FormData();
      documents.append('userId', employeeData.userId);
      uploadedFiles.forEach((fileObj, index) => {
        documents.append("documents[" + index + "].FileWrapper.File", fileObj.file);
        documents.append("documents[" + index + "].documentTypeId", fileObj.documentTypeId);
        documents.append("documents[" + index + "].documentName", fileObj.documentName);

      });

      if (employeeData.userId) {

        const res = await axios.post(
          `https://api.aspolite.com/api/employee/registerKycDocuments?userId=${employeeData.userId}`,
          documents,
          {
            headers: {
              Authorization:`Bearer ${token}`
            },
          }
        )
   
        
        navigate("/empVerification");
        
        navigate("/empVerification", { state: { employeeData } })
      } else {
        documents.append('userId', userId ? userId : employeeData.userId);
        const res = await axios.post(
          "https://api.aspolite.com/api/employee/registerKycDocuments",
          documents,
          {
            headers: {
              Authorization:`Bearer ${token}`
            },
          }
        );

        
        navigate("/empVerification");
      }

    }catch (error) {
      console.error(error);
      alert("An error occurred while logging in");
    }
  }
  return (
    <div>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmainemp" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h3>Employee Registration</h3>
          </div>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={2} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <div>
            <h3>Upload Files</h3>
            <div id="updbox">
              <label>CV</label>
              {uploadedFiles && uploadedFiles.find((file) => file.documentTypeId === 1) ? (
                <div>
                  <p>Uploaded: CV</p>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginRight:"10px"}}
                    onClick={() => handleDownload(uploadedFiles.find((file) => file.documentTypeId === 1))}
                  >
                   < DownloadIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDel(uploadedFiles.findIndex((file) => file.documentTypeId === 1))}
                  >
                   < DeleteIcon/>
                  </Button>
                </div>
              ) : (
                <TextField
                  type="file"
                  style={{ backgroundColor: "white" }}

                  onChange={(e) =>
                    handleFileChange(e.target.files[0], "C.V", "1")
                  }
                />
              )}
            </div>
            <div id="updbox">
              <label>Identify/Address Proof</label>
              {uploadedFiles && uploadedFiles.find((file) => file.documentTypeId === 2) ? (
                <div>
                  <p>Uploaded: Identity Proof</p>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginRight:"10px"}}
                    onClick={() => handleDownload(uploadedFiles.find((file) => file.documentTypeId === 2))}
                  >
                  < DownloadIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDel(uploadedFiles.findIndex((file) => file.documentTypeId === 2))}
                  >
                   < DeleteIcon/>
                  </Button>
                </div>
              ) : (
                <TextField
                  type="file"
                  style={{ backgroundColor: "white" }}
                  onChange={(e) =>
                    handleFileChange(e.target.files[0], "IdentityProof", "2")
                  }
                />
              )}
            </div>
            <div id="updbox">
              <label>PCC</label>
              {uploadedFiles && uploadedFiles.find((file) => file.documentTypeId === 3) ? (
                <div>
                  <p>Uploaded: PCC</p>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginRight:"10px"}}
                    onClick={() => handleDownload(uploadedFiles.find((file) => file.documentTypeId === 1))}
                  >
                     < DownloadIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDel(uploadedFiles.findIndex((file) => file.documentTypeId === 1))}
                  >
                  < DeleteIcon/>
                  </Button>
                </div>
              ) : (
                <TextField
                  type="file"
                  style={{ backgroundColor: "white" }}
                  onChange={(e) =>
                    handleFileChange(e.target.files[0], "PCC", "3")
                  }
                />
              )}
            </div><div id="updbox">
              <label>ESIC TIC Form</label>
              {uploadedFiles && uploadedFiles.find((file) => file.documentTypeId === 4) ? (
                <div>
                  <p>Uploaded: ESIC</p>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginRight:"10px"}}
                    onClick={() => handleDownload(uploadedFiles.find((file) => file.documentTypeId === 4))}
                  >
                      < DownloadIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(uploadedFiles.findIndex((file) => file.documentTypeId === 4))}
                  >
                    < DeleteIcon/>
                  </Button>
                </div>
              ) : (
                <TextField
                  type="file"
                  style={{ backgroundColor: "white" }}
                  onChange={(e) =>
                    handleFileChange(e.target.files[0], "ESIC", "4")
                  }
                />
              )}
            </div>
            <h3>Upload Educaitonal Certificates</h3>
            {uploadedFiles && uploadedFiles
              .filter((file) => file.documentTypeId === 5)
              .map((uploadFile, fileIndex) => (
                <div key={fileIndex}>
                  <p>Uploaded: {uploadFile.documentName}</p>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginRight:"10px"}}
                    onClick={() => handleDownload(uploadFile)}
                  >
                     < DownloadIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(uploadedFiles, fileIndex)}
                  >
                   < DeleteIcon/>
                  </Button>
                </div>
              ))}


            <div id="mulupdbox">
              {eduFiles.map((eduFile, index) => (
                <div key={index} id="kycbox">
                  <div id="empbox">
                    <label>Certificate Name</label>
                    <TextField
                      id={`certificateName-${index}`}
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Enter Certificate Name"
                      style={{ width: "50% !important" }}
                      value={eduFile.certificateName}
                      onChange={(e) => {
                        const updatedEduFiles = [...eduFiles];
                        updatedEduFiles[index].certificateName = e.target.value;
                        setEduFiles(updatedEduFiles);
                      }}
                    />
                  </div>

                  <TextField
                    type="file"
                    style={{ backgroundColor: "white" }}
                    onChange={(e) =>
                      handleFileChange(

                        e.target.files[0],
                        eduFile.certificateName,
                        "5"
                      )
                    }
                  />
                </div>
              ))}


              {eduFiles.length < 5 && (
                <div id="Addbtn">
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      if (eduFiles.length + uploadedFiles.filter((file) => file.documentTypeId === 5).length < 5) {
                        setEduFiles((prevEduFiles) => [
                          ...prevEduFiles,
                          { certificateName: "", file: null },
                        ]);
                      } else {
                        alert("You can only add up to 5 educational certificates.");
                      }
                    }}
                  >
                    Add
                  </Button>
                </div>
              )}

            </div>

            <h3>Upload Other Certificates</h3>
            {uploadedFiles
              .filter((file) => file.documentTypeId === 6)
              .map((uploadFile, fileIndex) => (
                <div key={fileIndex}>
                  <p>Uploaded: {uploadFile.documentName}</p>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{marginRight:"10px"}}
                    onClick={() => handleDownload(uploadFile)}
                  >
                    < DownloadIcon/>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(uploadedFiles, fileIndex)}
                  >
                  < DeleteIcon/>
                  </Button>
                </div>
              ))}
            <div id="mulupdbox">
              {otherFiles.map((other, index) => (
                <div key={index} id="kycbox">
                  <div id="empbox">
                    <label>Certificate Name</label>
                    <TextField
                      id={`certificateName-${index}`}
                      className="txtfield"
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Enter Certificate Name"
                      style={{ width: "50% !important" }}
                      value={other.certificateName}
                      onChange={(e) => {
                        const updatedOtherFiles = [...otherFiles];
                        updatedOtherFiles[index].certificateName =
                          e.target.value;
                        setOtherFiles(updatedOtherFiles);
                      }}
                    />
                  </div>
                  <TextField
                    type="file"
                    style={{ backgroundColor: "white" }}
                    onChange={(e) =>
                      handleFileChange(
                        e.target.files[0],
                        other.certificateName,
                        "6"
                      )
                    }
                  />
                </div>
              ))}

              {otherFiles.length < 5 && (
                <div id="Addbtn">
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}

                    onClick={() => {
                      if (eduFiles.length + uploadedFiles.filter((file) => file.documentTypeId === 6).length < 5) {
                        setOtherFiles((prevOtherFiles) => [
                          ...prevOtherFiles,
                          { certificateName: "", file: null },
                        ]);
                      } else {
                        alert("You can only add up to 5 educational certificates.");
                      }
                    }}

                  >
                    Add
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
             <Button
                variant="contained"
                className="subbtn"
                color="success"
                onClick={hanldePrevious}
                style={{marginRight:"10px"}}
              >
                <NavigateBeforeIcon />
                Previous
              
              </Button>
            <Button
              variant="contained"
              className="subbtn"
              onClick={handleSubmit}
            >
              Next
              <NavigateNextIcon />
            </Button>
          </div>
        </Box>
      </Box>
    </div>

  );
}

export default Kyc_docs;

