import React from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../screens/subcom.css";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import moment from "moment/moment";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import DangerousIcon from "@mui/icons-material/Dangerous";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LiabilityChart from "./liabilityChartReport";
function LiabilityReport() {
  const URL2 = "https://api.aspolite.com/api/report/liability";
  const URL1 = "https://api.aspolite.com/api/report/liabilityDetails";
  const URL3 = "https://api.aspolite.com/api/master/location";
  const URL = "http://localhost:3002/api/bonus/save";
  const location = useLocation();
  const data = location.state;
  const shiftData = data && data.selectedShift ? data.selectedShift : {};

  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [bonusDate, setBounusDate] = useState();

  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [Tomonth, setToMonth] = useState();
  const [Frommonth, setFromMonth] = useState();
  const [Toyear, setToYear] = useState();
  const [Fromyear, setFromYear] = useState();
  const [bonusAmount, setBonusAmount] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [chartData, setChartData] = useState()
const [tableData ,setTableData] = useState()
  const navigate = useNavigate();
  const columns = [
    { id: "SrNo", label: "Sr No", align: "center" },
    { id: "EmpCode", label: "Emp Code", align: "center" },
    { id: "EmpName", label: "Emp Name", align: "center" },
    { id: "DateofJoining", label: "Date of Joining", minWidth: 50, align: "center" },
    {
      id: "salary",
      label: "Salary",
      align: "center",
      
    },{
      id: "bonus",
      label: "Bonus",
      align: "center",
      
    },{
      id: "graduity",
      label: "Graduity",
      align: "center",
      
    },

  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChange = (e) => {
    setLocationId(e.target.value);
  };
  const token = localStorage.getItem("token");



  const handleCheckboxToggle = (row) => {
    if (checkedRows.includes(row)) {
      setCheckedRows(checkedRows.filter((checkedRow) => checkedRow !== row));
    } else {
      setCheckedRows([...checkedRows, row]);
    }
  };


  const fetchHandler = async () => {
    try {
      const res = await axios.get(URL3, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchHandler();
  }, []);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleGenrateReport = async () => {
    try {
      const res = await axios.post(
        URL2,
        {
          fromMonth: Frommonth.format("MMM"),
          fromYear: Fromyear.format("YYYY"),
          toMonth:Tomonth.format("MMM"),
          toYear:Toyear.format("YYYY")
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setRows(res.data);
      setChartData(res.data);
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  };
  const handleBack = () => {
    navigate("/shift");
  };

  const handleBarClick = async (item) => {
    console.log(item);
  
    let selectedOption = null;
  
    if (options.length > 0) {
      selectedOption = options.find(
        (option) => item.locationName === option.name
      );
      console.log(selectedOption);
    }
  
    if (!selectedOption) {
      console.error("Selected option not found");
      alert("No matching location found");
      return;
    }
  
    try {
      const res = await axios.post(
        URL1,
        {
          locationId: selectedOption.id,
          fromMonth: Frommonth.format("MMM"),
          fromYear: Fromyear.format("YYYY"),
          toMonth: Tomonth.format("MMM"),
          toYear: Toyear.format("YYYY"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setTableData(res.data);
    } catch (error) {
      console.error(error);
      alert("An error occurred while fetching details");
    }
  };
  



  async function handleSubmit(e) {
    e.preventDefault();
    const location = options.filter((opt) => opt.id === locationId);
    try {
      const selectedRows = rows.filter((row) => row.checked);

      const selectedEmployees = selectedRows.map((row) => ({
        userId: row.userId,
        empCode: row.empCode,
        empName: row.empName,
        joining: row.joiningDate, 
        bonusAmount: row.amount || bonusAmount,
      }));
      const res = await axios.post(
        URL,
        {
          fromDate: fromDate,
          toDate: toDate,
          date: bonusDate,
          locationId: locationId,
          location: location.name,
          employees: selectedEmployees,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("/bonus");
    } catch (error) {
      console.error(error);
      alert("An error occurred while creating new Shift");
    }
  }


  return (
    <div>
      <Navbar />

      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" id="boxmain" sx={{ flexGrow: 1, p: 3 }}>
          <div>
            <h1>Liability Report</h1>
          
            <div id="row2">
              <div id="empbox">
                <label>From Year</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="YYYY"
                      views={["year"]}
                      margin="normal"
                      id="date-picker-inline"
                      value={Fromyear}
                      onChange={(e) => {
                        setFromYear(e);
                      }}
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="empbox">
                <label>From Month</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="MMM"
                      views={["month"]}
                      margin="normal"
                      id="date-picker-inline"
                      value={Frommonth}
                      onChange={(e) => {
                        setFromMonth(e);
                      }}
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
             
              <div id="empbox">
                <label>To Year</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="YYYY"
                      views={["year"]}
                      margin="normal"
                      id="date-picker-inline"
                      value={Toyear}
                      onChange={(e) => {
                        setToYear(e);
                      }}
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div id="empbox">
                <label>To Month</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]} size="small">
                    <DatePicker
                      size="small"
                      fullWidth
                      disableToolbar
                      variant="inline"
                      format="MMM"
                      views={["month"]}
                      margin="normal"
                      id="date-picker-inline"
                      value={Tomonth}
                      onChange={(e) => {
                        setToMonth(e);
                      }}
                      required
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            </div>
        
          <div id="cretebtn">
            <Button variant="contained" onClick={handleGenrateReport}>
              Generate Report
            </Button>
          </div>
         
          {chartData && (
              <LiabilityChart data={chartData} onBarClick={handleBarClick} />
            )}
 {tableData && tableData.length > 0 && (
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{row.empCode}</TableCell>
                            <TableCell align="center">{row.empName}</TableCell>
                            <TableCell align="center">{moment(row.dateOfJoining).format("DD/MMM/YYYY")}</TableCell>
                            <TableCell align="center">{row.salary}</TableCell>
                            <TableCell align="center">{row.bonus}</TableCell>
                            <TableCell align="center">{row.graduity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={tableData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </Paper>
              )}
       
        </Box>
      </Box>
    </div>
  );
}
export default LiabilityReport;
